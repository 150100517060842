import {
  DefaultSeo as NextSeo,
  OrganizationJsonLd,
  DefaultSeoProps,
} from 'next-seo';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getQueryFromPath } from '~/lib/util';
import { ensureAbsoluteUrl } from '~/lib/url';

import {
  CANONICAL_WITH_PAGE_QUERY,
  HOMEPAGE_PATHNAMES,
} from '~/lib/util/constants';

export function DefaultSeo() {
  const router = useRouter();

  // strip the query string params for the canonical url
  const canonicalUrl = useMemo(() => {
    // Canonical URL for root/home page should always be '/'.
    if (HOMEPAGE_PATHNAMES.includes(router.pathname)) {
      return ensureAbsoluteUrl('/');
    }

    // We need to set canonical pagination pages (if in list) but without other params.
    if (CANONICAL_WITH_PAGE_QUERY.includes(router.pathname)) {
      const query = getQueryFromPath(router.asPath);

      // We don't want to include the page query param in the canonical URL.
      if (query.has('page')) {
        return ensureAbsoluteUrl(router.asPath.split('?')[0].split('!!')[0]);
      }
    }

    return ensureAbsoluteUrl(router.asPath.split('?')[0].split('!!')[0]);
  }, [router.asPath, router.pathname]);

  const options: DefaultSeoProps = {
    title: 'Browse software deals for your business.',
    titleTemplate: '%s | AppSumo',
    description:
      'Top software deals for entrepreneurs at incredible prices. Discover the latest productivity, marketing, and content tools for your business with no monthly fees.',
    twitter: {
      handle: '@appsumo',
      site: '@appsumo',
      cardType: 'summary_large_image',
    },
    openGraph: {
      type: 'website',
      siteName: 'AppSumo',
      images: [
        {
          url: 'https://appsumo2-cdn.appsumo.com/static/images/meta/appsumo-logo-meta.png',
          alt: 'AppSumo',
        },
      ],
      url: router.asPath,
    },
    canonical: canonicalUrl,
  };

  return (
    <>
      <NextSeo {...options} />
      <OrganizationJsonLd
        name="AppSumo"
        url="https://appsumo.com"
        logo="https://appsumo2nuxt-cdn.appsumo.com/as-appsumo-logo-dark.svg"
        sameAs={[
          'https://twitter.com/AppSumo',
          'https://www.instagram.com/appsumo/',
          'https://www.facebook.com/appsumo/',
          'https://www.youtube.com/channel/UCRrAZbOmqB3pX7dWHC_-wEQ',
          'https://www.tiktok.com/@appsumo',
        ]}
      />
    </>
  );
}
