import dayjs from 'dayjs';

import { PDP_URL_PREFIX } from './constants';
import { UserObjectProps } from '~/types/user';
import { CartProps } from '~/types/cart';
import { Parameters } from '~/types/gtag';
import { CheckoutInvoiceProps } from '~/types/checkout';
import { Deal } from '~/types/deal';
import { formatToHumanReadableDate } from './helpers';

enum UserType {
  Customer = 'customer',
  Staff = 'staff',
  Betaling = 'betaling',
  Plus = 'plus',
}

// Wrapper for better TS support
export function gtag(
  command: string,
  value: string | Record<any, any>,
  parameters?: Partial<Parameters>,
) {
  if (parameters) {
    // @ts-ignore
    gtagFn(command, value, parameters);
  } else {
    // @ts-ignore
    gtagFn(command, value);
  }
}

export function gtagFn() {
  window.dataLayer = window.dataLayer || [];

  // Using the spread operator won't work, so `arguments` is required.
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export function getGA4UserType(user: UserObjectProps): UserType {
  let userType: UserType = UserType.Customer;

  if (user.is_staff) {
    userType = UserType.Staff;
  } else if (user.is_betaling) {
    userType = UserType.Betaling;
  } else if (user.has_plus) {
    userType = UserType.Plus;
  }

  return userType;
}

export function getCheckoutDataGA4(cart: CartProps): Parameters {
  return {
    currency: 'USD',
    value: cart.subtotal,
    hasMultipleProducts: cart.cart_items.length > 1,
    items: cart.cart_items.map((x) => {
      const plan = x.deal.plans.find((p) => p.id === x.deal_plan.id);

      return {
        item_name: x.deal.public_name,
        item_variant: plan?.tier ?? 1,
        item_id: x.deal.id,
        price: Number(x.price),
        item_brand: x.deal.listing_type,
        item_category: x.deal.taxonomy?.group || '',
        item_category2: x.deal.taxonomy?.category || '',
        item_category3: x.deal.taxonomy?.subcategory || '',
        item_category4: x.deal.taxonomy?.product_tag || '',

        // GA4 custom parameter
        deal_status: x.deal.deal_status,

        // UA Custom parameters
        name: x.deal.public_name,
        id: x.deal.id,
        url: `${PDP_URL_PREFIX}${x.deal.slug}/`,
        comparisonPrice: plan?.original_price,
        quantity: x.quantity,
        image: x.deal.image,
        subheader: x.deal.subheader,
        endDate: x.deal_plan.end_date ?? '',
        formattedEndDate: x.deal_plan.end_date
          ? formatToHumanReadableDate(x.deal_plan.end_date, undefined, 'en-US')
          : '',
        averageRating: x.deal.average_rating,
        topReview: x.deal.top_review,
      };
    }),
  };
}

export function sendCheckoutCompleteDataGA4(data: CheckoutInvoiceProps): void {
  try {
    if (data.invoice?.total > 0) {
      const items =
        data.invoice?.invoice_items?.map((item) => ({
          item_name: item.deal?.public_name,
          item_variant: item.deal_plan?.tier,
          item_id: item.deal?.id,
          price: item.total,
          item_brand: item.deal?.listing_type,
          item_category: item.deal?.taxonomy?.group,
          item_category2: item.deal?.taxonomy?.category,
          item_category3: item.deal?.taxonomy?.subcategory,
          item_category4: item.deal?.taxonomy?.product_tag,
          subheader: item.deal?.product?.story?.subheader,
          url: `https://appsumo.com${item?.deal?.get_absolute_url}`,
          endDate: item.deal.deal_end_date,
          comparisonPrice: item.original_price,
          quantity: 1,
          image: item.deal?.media_url ?? '',
        })) ?? [];

      const parameters = {
        currency: 'USD',
        value: data.invoice?.total,
        transaction_id: data.invoice?.uuid,
        customerStatus: data.user?.deals_purchased > 1 ? 'RETURNING' : 'NEW',
        items: items,
      };

      // Per comment in appsumo-2 old checkout complete page:
      // There's a bug on GTM that doesn't add the eventModel to tags when using tag sequencing,
      // so we need to add these parameters to the root of the dataLayer. These parameters will
      // be used only in some tags related to the purchase.
      gtag('set', { purchaseData: parameters });
      gtag('event', 'purchase', parameters);
    } else {
      const items =
        data.invoice?.invoice_items?.map((item) => ({
          item_name: item.deal?.public_name,
          url: `https://appsumo.com${item?.deal?.get_absolute_url}`,
          image: item.deal?.media_url ?? '',
          item_id: item.deal?.id,
        })) ?? [];

      const parameters = {
        items,
        transaction_id: data.invoice?.uuid,
      };

      gtag('set', { purchaseData: parameters });
      gtag('event', 'free_purchase', parameters);
    }
  } catch (error) {
    // Alert in sentry
    console.error('Error sending GA4 checkout event', error);
  }
}

export function getViewItemData(deal: Deal): Parameters {
  return {
    currency: 'USD',

    // Value of the item from a CVR perspective
    value: deal.default_price || 0,
    items: [
      {
        item_name: deal.public_name,
        item_variant: deal.default_plan.tier,
        item_id: deal.id,
        price: deal.default_price || 0,
        affiliation: deal.products?.[0]?.partner || '',
        item_brand: deal.listing_type || '',
        item_category: deal.taxonomy?.group || '',
        item_category2: deal.taxonomy?.category || '',
        item_category3: deal.taxonomy?.subcategory || '',
        item_category4: deal.taxonomy?.productTag || '',

        // Custom parameters
        deal_status: deal.deal_status.toLowerCase(),
        subheader: deal.products?.[0]?.story?.subheader || '',
        url: `https://appsumo.com${deal.get_absolute_url}`,
        endDate: deal.dates?.end_date
          ? dayjs(deal.dates?.end_date).format('MM/DD/YYYY hh:mm A')
          : '',
        comparisonPrice: deal.original_price || 0,
        averageRating: deal.deal_review?.average_rating || 0,
        topReview: deal.top_5_reviews?.[0]?.comment || '',
        image: deal.media_url ? `${deal.media_url}?width=250&height=250` : '',
      },
    ],
  };
}
