import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useEffectOnce } from 'usehooks-ts';

import { DEFAULT_SWR_OPTIONS } from '~/constants/global';
import { useCart } from '~/lib/cart';
import { fetchJson } from '~/lib/fetch';
import useUser from '~/lib/user';
import {
  Campaign,
  CampaignConfigsHookProps,
  CampaignsMap,
} from '~/types/campaign';

export default function useCampaignConfigs(): CampaignConfigsHookProps {
  const [mounted, setMounted] = useState(false);
  const [campaignConfigs, setCampaignConfigs] = useState<CampaignsMap>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeAutoCampaign, setActiveAutoCampaign] = useState<
    Campaign | undefined
  >();
  const { user, session } = useUser();
  const { cart } = useCart();

  useEffectOnce(() => {
    setMounted(true);
  });

  const currentCampaignSlugs: string = session?.campaigns.join('|') || '';
  const campaignConfigUrl = `/api/campaigns/configs/?campaign_slugs=${currentCampaignSlugs}`;

  // This hook is called outside SWRConfig scope, setting the options manually
  const { data, error } = useSWR(
    mounted && session?.campaigns?.length ? campaignConfigUrl : null,
    fetchJson,
    {
      DEFAULT_SWR_OPTIONS,
      revalidateOnFocus: true,
      revalidateIfStale: false,
      onSuccess: () => setIsLoading(false),
      onError: () => setIsLoading(false),
    },
  );

  // If there aren't campaigns in the current session, set `isLoading` to `false`,
  // otherwise the UI will never display the nav pills in the header because
  // `useSWR` will never get executed on this hook.
  useEffect(() => {
    if (Array.isArray(session?.campaigns) && !session?.campaigns.length) {
      setIsLoading(false);
    }
  }, [session?.campaigns]);

  useEffect(() => {
    if (!error) {
      setCampaignConfigs(data);

      if (data) {
        // Set active campaign to first auto campaign returned.
        setActiveAutoCampaign(
          Object.values(data as CampaignsMap).find(
            (campaign: Campaign) => campaign.allow_auto_coupons,
          ),
        );
      }
    } else {
      console.error('Failed to fetch campaign configs', error);
    }
  }, [data, error]);

  const showCampaignIncentive = useMemo(
    () =>
      !user?.id && cart?.has_auto_campaign_product && !cart?.coupon?.coupon_id,
    [user, cart],
  );

  const allowAsksumoDiscount: boolean = useMemo(
    () =>
      !activeAutoCampaign ||
      !!activeAutoCampaign?.campaign_config?.allow_asksumo_discount,
    [activeAutoCampaign],
  );

  return {
    activeAutoCampaign,
    allowAsksumoDiscount,
    campaignConfigs,
    showCampaignIncentive,
    isLoading,
  };
}
