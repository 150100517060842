export const validBrowseParameters: string[] = [
  'page',
  'query',
  'status',
  'sort',
  'type',
  'price_type',
  'price_from',
  'price_to',
  'select',
  'after',
  'campaign',
  'gdpr',
];

interface Params {
  [key: string]: string | number | boolean;
}

export function filterBrowseParams(
  params: Params,
  validAttributes: string[] = [],
): Params {
  const validKeys = Object.keys(params).filter(
    (key) =>
      validBrowseParameters.includes(key) || validAttributes.includes(key),
  );
  const validParams: Params = { ...params };

  Object.keys(validParams).forEach((key) => {
    if (!validKeys.includes(key)) {
      delete validParams[key];
    }
  });

  return validParams;
}
