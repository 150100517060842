export enum CommentType {
  Question = 'dealcomment',
  Review = 'dealreview',
}

export enum CommentV2Type {
  Question = 'question',
  Review = 'review',
  Reply = 'reply',
  Comment = 'comment',
}

export enum ApprovalStatus {
  Denied,
  Approved,
  Unapproved,
}

export enum ModerationStatus {
  Unresolved,
  Resolved,
}

export enum UserStatus {
  Whitelist = 'whitelist',
  Blacklist = 'blacklist',
}
