import { memo, useMemo } from 'react';

import { Campaign } from '~/types/campaign';
import { SessionObjectProps, UserSegment } from '~/types/user';

import AskSumoPill from './AskSumoPill';
import CampaignNavPill from './CampaignNavPill';
import PlusDiscountPill from './PlusDiscountPill';
import ShopPlusExclusivesPill from './ShopPlusExclusivesPill';

export type BaseNavPillProps = {
  /** Whether the current user is authenticated or not. */
  isAuthenticated?: boolean;
  /** Whether the current (authenticated) user is subscribed to Plus or not. */
  hasPlus?: boolean;
  /** The current active auto campaign (allow auto coupons). */
  activeAutoCampaign?: Campaign;
  /** The current user session. */
  session?: SessionObjectProps;
};

export default memo(function BaseNavPill({
  isAuthenticated,
  hasPlus,
  activeAutoCampaign,
  session,
}: Readonly<BaseNavPillProps>) {
  const segment: UserSegment = useMemo(() => {
    if (!isAuthenticated) return 'unauthenticated';
    return hasPlus ? 'plus' : 'authenticated';
  }, [hasPlus, isAuthenticated]);

  const campaignPill = useMemo(() => {
    const pillConfig = activeAutoCampaign?.campaign_config.nav_pills.find(
      (x) => x.user_segment === segment,
    );

    return pillConfig ? <CampaignNavPill config={pillConfig} /> : null;
  }, [activeAutoCampaign, segment]);

  // Unauthenticated users.
  if (!isAuthenticated) {
    return campaignPill || <AskSumoPill />;
  }

  // Authenticated users without Plus.
  if (isAuthenticated && !hasPlus) {
    return campaignPill || <PlusDiscountPill session={session} />;
  }

  // Authenticated users with Plus.
  if (hasPlus) {
    return campaignPill || <ShopPlusExclusivesPill />;
  }

  return null;
});
