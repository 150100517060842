import { getEsBrowseUrl } from '~/lib/browse/fetch';
import useSWR from 'swr';
import { useCallback } from 'react';
import { useCart } from '~/lib/cart';
import { CartSkuCard } from '~/components/cart/shelf/CartSkuCard';

interface Props {
  dealId?: number;
  dealIds?: number[];
}

export function CartAlsoBought({ dealId, dealIds }: Props) {
  const { lineItemAdd } = useCart();

  const url = getEsBrowseUrl(
    {
      status: 'current',
      sort: 'recommended',
    },
    {
      alsobought: dealIds ? dealIds.join(',') : dealId,
    },
    2,
  );
  const { data: { deals } = {} } = useSWR(url);

  const handleAddToCart = useCallback(
    (id: number) => {
      lineItemAdd(id);
    },
    [lineItemAdd],
  );

  return (
    <>
      {deals?.length > 0 && (
        <div className="mx-4 py-4">
          <strong>Customers also bought:</strong>
          <div className="mt-5 grid grid-cols-2 gap-4">
            {deals?.map((deal: any) => (
              <CartSkuCard
                key={deal.id}
                deal={deal}
                onClick={handleAddToCart}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
