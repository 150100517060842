export function replaceURLParam(
  url: string,
  paramName: string,
  paramValue: string,
): string {
  const pattern = new RegExp(`\\b(${paramName}=).*?(&|$)`);
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, `$1${paramValue}$2`);
  }
  return `${url}${url.indexOf('?') > 0 ? '&' : '?'}${paramName}=${paramValue}`;
}

export function removeURLParam(url: string, paramName: string): string {
  const pattern = new RegExp(`\\b(${paramName}=).*?(&|$)`);
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '').replace(/\?+$/, '');
  }
  return url;
}

export function ensureAbsoluteUrl(url: string) {
  if (url.startsWith('http')) {
    return url;
  }

  return new URL(url, 'https://appsumo.com').href;
}
