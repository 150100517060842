import { useRef } from 'react';

import { DealImpressionProps } from '~/types/deal';

export function useDealImpressions(): DealImpressionProps {
  const dealsRef = useRef<Array<number>>([]);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const resetImpressions = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    // reset refs
    intervalRef.current = null;
    dealsRef.current = [];
  };

  const processImpressions = () => {
    // If no deals to process, break the interval
    if (!dealsRef.current.length || !Array.isArray(dealsRef.current)) {
      resetImpressions();
      return;
    }

    try {
      // extra catch to ensure we don't fire off unecessary requests
      if (!dealsRef.current || !dealsRef.current.length) return;

      const uniqueIds = [...new Set(dealsRef.current)];
      const bug = new Image();
      const isSSR = typeof window === 'undefined';
      const baseURL =
        (isSSR
          ? process.env.DJANGO_INTERNAL_URL
          : process.env.NEXT_PUBLIC_DJANGO_PUBLIC_URL) || '';

      // Mimic the was Nuxt was set up to track impressions
      bug.src = `${baseURL}/deal/impression/?deal_ids=${uniqueIds.join('|')}`;
      bug.alt = '';
      bug.style.display = 'none';
      bug.addEventListener(
        'load',
        () => {
          bug.parentNode?.removeChild(bug);
        },
        { once: true },
      );
      bug.addEventListener(
        'error',
        () => {
          bug.parentNode?.removeChild(bug);
        },
        { once: true },
      );

      document.body.appendChild(bug);
    } catch (err) {
      // eslint-disable-next-line
      console.log('Error Parsing Impressions:', err);
    }
    resetImpressions();
  };

  function addDealImpression(dealId: number) {
    if (!intervalRef.current) {
      const interval = setInterval(processImpressions, 1000);
      intervalRef.current = interval;
    }

    // Use the current ID list to ensure the ref has most upto date list
    dealsRef.current = [...(dealsRef.current ?? []), dealId];
  }

  return { addDealImpression, resetImpressions };
}
