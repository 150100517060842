import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBell } from '@fortawesome/free-solid-svg-icons';
import useUser from '~/lib/user';
import { useState, useEffect } from 'react';
import { CartShelf } from '~/components/cart/shelf/CartShelf';
import { useRouter } from 'next/router';
import { useEvent } from '~/lib/events';
import { Transition } from '@headlessui/react';
import Script from 'next/script';
import { Link } from '~/components/global/Link';
import { useCart } from '~/lib/cart';

const GETBEAMER_SEGMENT_TYPE = {
  ANONYMOUS: 'anonymous',
  PLUS: 'plus',
  NON_PLUS: 'non plus',
} as const;

type GetBeamerSegmentType =
  (typeof GETBEAMER_SEGMENT_TYPE)[keyof typeof GETBEAMER_SEGMENT_TYPE];

interface GetBeamerConfig {
  product_id: string;
  selector: string;
  top: number;
  right: number;
  user_email?: string;
  user_firstname?: string;
  user_lastname?: string;
  user_type: GetBeamerSegmentType;
  filter: GetBeamerSegmentType;
}

const GET_BEAMER_PRODUCT_ID = process.env.NEXT_PUBLIC_GET_BEAMER_PRODUCT_ID;

export default function NavControls() {
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const [beamerConfig, setBeamerConfig] = useState<GetBeamerConfig | null>(
    null,
  );
  const { user } = useUser();
  const { cart } = useCart();

  const router = useRouter();

  const handleCartAdding = () => {
    if (router?.pathname === '/cart') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setCartIsOpen(true); // let's not open cart drawer on /cart page
    }
  };

  useEvent('cart:adding', handleCartAdding);

  // close the cart when we navigate to a new page
  useEffect(() => {
    const handleRouteChange = () => {
      setCartIsOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const isNextCartShelf = false; // temporary until we re-introduce shelf experiment

  useEffect(() => {
    if (GET_BEAMER_PRODUCT_ID && user) {
      const config: GetBeamerConfig = {
        product_id: GET_BEAMER_PRODUCT_ID,
        selector: '#beamerButton',
        top: -10,
        right: -10,
        user_type: GETBEAMER_SEGMENT_TYPE.ANONYMOUS,
        filter: GETBEAMER_SEGMENT_TYPE.ANONYMOUS,
      };
      if (user) {
        const userSegmentType: GetBeamerSegmentType = user.has_plus
          ? GETBEAMER_SEGMENT_TYPE.PLUS
          : GETBEAMER_SEGMENT_TYPE.NON_PLUS;

        config.user_email = user.email;
        config.user_firstname = user.first_name;
        config.user_lastname = user.last_name;
        config.user_type = userSegmentType;
        config.filter = userSegmentType;
      }

      setBeamerConfig(config);
    }
  }, [user]);

  const hasCartItems = !!cart && cart?.count > 0; // Transition (below) needs explicit boolean

  return (
    <div className="ml-2 flex items-center justify-end gap-6">
      {isNextCartShelf && (
        <CartShelf onClose={() => setCartIsOpen(false)} open={cartIsOpen} />
      )}
      {beamerConfig ? (
        <>
          <Script
            id="getbeamer"
            dangerouslySetInnerHTML={{
              __html: `var beamer_config = ${JSON.stringify(beamerConfig)};`,
            }}
          />
          <Script
            src="https://app.getbeamer.com/js/beamer-embed.js"
            strategy="afterInteractive"
          />
          <div id="beamerButton" className="relative hover:text-gray-700">
            <FontAwesomeIcon
              className="!h-5"
              icon={faBell}
              width="20"
              height="20"
            />
          </div>
        </>
      ) : (
        <div className="w-[20px]" />
      )}
      <div className="relative">
        {!isNextCartShelf && (
          // TODO: remove this once we have the new cart
          // eslint-disable-next-line @next/next/no-html-link-for-pages
          <Link href="/cart/" className="hover:text-gray-700">
            <span className="sr-only">Cart</span>
            <FontAwesomeIcon
              className="!h-5"
              icon={faShoppingCart}
              width="20"
              height="20"
            />

            <Transition
              as="span"
              show={hasCartItems}
              enter="transition ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute right-[-10px] top-[-10px] flex h-[20px] w-[20px] items-center justify-center rounded-full bg-red-500 text-[11.5px] font-bold text-white"
            >
              {cart?.count}
            </Transition>
          </Link>
        )}

        {isNextCartShelf && (
          <button
            className="hover:text-gray-700"
            onClick={() => setCartIsOpen(true)}
          >
            <span className="sr-only">Cart</span>
            <FontAwesomeIcon
              className="!h-5"
              icon={faShoppingCart}
              width="20"
              height="20"
            />

            <Transition
              as="span"
              show={hasCartItems}
              enter="transition ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute right-[-10px] top-[-10px] flex h-[20px] w-[20px] items-center justify-center rounded-full bg-red-500 text-[11.5px] font-bold text-white"
            >
              {cart?.count}
            </Transition>
          </button>
        )}
      </div>
    </div>
  );
}
