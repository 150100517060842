import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';

/**
 * Scroll to top button that appears when the user has scrolled down the page.
 */
export function ScrollToTop() {
  const [userHasScrolledDown, setUserHasScrolledDown] = useState(false);

  useEffect(() => {
    const windowScrollEvent = () => {
      const userHasScrolled = window.scrollY >= window.screen.height;

      if (userHasScrolled !== userHasScrolledDown) {
        setUserHasScrolledDown(userHasScrolled);
      }
    };

    window.addEventListener('scroll', windowScrollEvent);

    return () => {
      window.removeEventListener('scroll', windowScrollEvent);
    };
  }, [userHasScrolledDown]);

  const hasBeaconContainer = typeof window !== 'undefined' && window.Beacon;

  return (
    <>
      {userHasScrolledDown && (
        <button
          className={`fixed ${
            hasBeaconContainer ? 'bottom-28' : 'bottom-20'
          } right-4 z-5 flex flex-col items-center justify-center rounded bg-gray-100/50 p-3 hover:bg-gray-100/75`}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <span className="text-xs">Scroll to top</span>
          <div className="mt-1 flex h-8 w-8 items-start justify-center rounded-full border-2 border-black">
            <FontAwesomeIcon icon={faChevronUp} className="mt-1 text-xs" />
          </div>
        </button>
      )}
    </>
  );
}
