export enum PROFILE_DATA_TYPES {
  ACCOUNT = 'account',
  BILLING = 'billing',
  TAX = 'tax',
}

export const PROFILE_DATA_URL = '/api/account/profile';
export const ACCOUNT_EMAILS_ENDPOINT = '/accounts/email/';

export enum ACCOUNT_PASSWORD_RESET_TYPE {
  UNKNOWN = 'unknown',
  PASSWORD_EXPIRED = 'password_expired',
  ACCOUNT_COMPROMISED = 'account_compromised',
  PASSWORD_FOUND_IN_LEAK = 'password_found_in_leak',
  PASSWORD_RESET = 'password_reset',
  NOT_REQUIRED = 'not_required',
  NEW_ACCOUNT = 'new_account',
}
