import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { currency } from '~/lib/format';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import taco from '~/public/taco.svg';

interface Props {
  deal: any;
  onClick: (id: number) => void;
}

export function CartSkuCard({ deal, onClick }: Props) {
  return (
    <button
      className="relative flex cursor-pointer flex-col gap-y-1.5"
      key={deal.id}
      onClick={() => onClick(deal.id)}
    >
      <Image
        src={`${deal.media_url}`}
        alt={deal.public_name}
        width={150}
        height={100}
        className="aspect-sku-card h-[100px] w-[150px] rounded-lg"
        unoptimized
      />
      {deal.card_description && (
        <p className="text-left text-sm line-clamp-2">
          {deal.card_description}
        </p>
      )}
      <div className="flex items-center gap-x-2 text-sm">
        <Image src={taco} alt="Rating" />
        <span className="text-bolt">{deal.deal_review?.average_rating}</span>(
        {deal.deal_review?.review_count})
      </div>
      <div className="text-sm">
        <strong>{currency(deal.price)}</strong>/lifetime
      </div>
      <div className="absolute top-0 left-0 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white shadow">
        <FontAwesomeIcon icon={faPlus} className="h-3 w-3 font-bold" />
      </div>
    </button>
  );
}
