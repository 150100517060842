import { Roboto, Barlow, Montserrat } from '@next/font/google';

const roboto = Roboto({
  weight: ['400', '500', '700'],
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-roboto',
  display: 'swap',
});

const barlow = Barlow({
  weight: ['400', '500', '600', '700'],
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-barlow',
  display: 'swap',
});

const montserrat = Montserrat({
  weight: ['400', '500', '600', '700'],
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-montserrat',
  display: 'swap',
});

export function getFontFamilyClass() {
  return `${roboto.variable} ${barlow.variable} ${montserrat.variable}`;
}
