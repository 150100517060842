import { ReactElement } from 'react';
import clsx from 'clsx';

import usePrivy from '~/lib/script/privy';
import { LAYOUT_TYPES } from '~/constants/global';

import { Footer } from '~/components/global/Footer';
import { HijackedUser } from '~/components/global/HijackedUser';
import SocialProofFooter from '~/components/global/SocialProofFooter';
import NavHeader from '~/components/navigation/NavHeader';
import { ScrollToTop } from '~/components/ui/ScrollToTop';
import AccountSidebar from '~/components/account/Global/AccountSidebar';

export default function BaseLayout({
  children,
  className,
  pageType,
  accountPage,
  ...props
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  pageType?: LAYOUT_TYPES;
  accountPage?: string;
}>): ReactElement {
  const isCollectionBrowsePage = (children as ReactElement)?.props
    ?.isCollectionBrowsePage;

  usePrivy();

  const isCartPage = pageType === LAYOUT_TYPES.CART;
  const isRedemptionPage = pageType === LAYOUT_TYPES.REDEMPTION;

  const classNames = clsx(
    isCartPage ? 'pt-[68px] md:pt-[85px]' : `pt-[111px] md:pt-[131px]`,
    isRedemptionPage && 'pt-[120px] md:pt-[140px]',
    pageType === LAYOUT_TYPES.CHECKOUT_COMPLETE && 'flex h-full grow',
  );

  const childComponent = accountPage ? (
    <AccountSidebar accountPage={accountPage}>{children}</AccountSidebar>
  ) : (
    children
  );

  return (
    <div className={className} {...props}>
      <NavHeader
        isCollectionBrowsePage={isCollectionBrowsePage}
        isCartPage={isCartPage}
      />
      <div id="appsumo-portal-root" />
      {/* Padding for fixed header */}
      <div id="headlessui-portal-root" className={classNames}>
        <HijackedUser />
        {childComponent}
      </div>
      {isCartPage && <SocialProofFooter />}
      <Footer />
      <ScrollToTop />
    </div>
  );
}
