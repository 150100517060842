import { Link } from '@appsumo/dorado-react';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Image from 'next/image';
import { ReactElement, useContext } from 'react';

import { GlobalContext } from '~/contexts/global';
import { CampaignIncentiveVersion } from '~/lib/campaigns/constants';
import { LOGIN_URL } from '~/lib/util/constants';

export function CampaignIncentive({
  className,
  version = CampaignIncentiveVersion.CHECKOUT,
}: {
  className?: string;
  version?: CampaignIncentiveVersion;
}): ReactElement {
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  if (!activeAutoCampaign) return <></>;

  const content = (
    <>
      <Link href={`${LOGIN_URL}?next=/checkout`} className="text-bolt">
        Log in or sign up
      </Link>{' '}
      to claim {activeAutoCampaign.discount_display} off
      {version === CampaignIncentiveVersion.CHECKOUT &&
        ` for ${activeAutoCampaign.name}`}
      !
    </>
  );

  const iconClasses = clsx(
    { 'text-2xl': version === CampaignIncentiveVersion.CHECKOUT },
    { 'self-center': version === CampaignIncentiveVersion.CART_SHELF },
  );

  const faIconClasses = clsx('mr-4 text-dollar', iconClasses);
  const contentClasses = clsx(
    version === CampaignIncentiveVersion.CART_SHELF
      ? 'text-sm font-bold'
      : 'font-medium',
  );
  const cartBadge = activeAutoCampaign?.campaign_config.cart_discount_badge;

  return (
    <>
      <div
        className={clsx(
          `flex bg-dollar/10 p-4 ${clsx(className, {
            'py-2': version === CampaignIncentiveVersion.CART_SHELF,
          })}`,
        )}
      >
        {cartBadge ? (
          <Image
            src={cartBadge}
            alt="Campaign Discount"
            width={24}
            height={24}
            className={iconClasses}
            data-testid="custom-icon"
          />
        ) : (
          <FontAwesomeIcon
            data-testid="default-icon"
            icon={faTag}
            className={faIconClasses}
          />
        )}
        <div className={contentClasses} data-testid="content-container">
          {content}
        </div>
      </div>
    </>
  );
}
