import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import useUser from '~/lib/user';
import { getCSRF } from '~/lib/discussions/utils';
import clsx from 'clsx';

export function HijackedUser(): ReactElement {
  const { session, user } = useUser();
  const router = useRouter();

  if (!session?.is_hijacked_user || !user) return <></>;

  const redirectUrl = `/hijack/release/?next=${session.hijacked_user_redirect_path}`;

  return (
    <div
      className={clsx('m-5', {
        'mt-[100px]': router?.pathname === '/checkout',
      })}
    >
      <form action={redirectUrl} method="POST" data-testid="hijack-form">
        <p className="float-left mr-1">
          You are currently working on behalf of {user.username}
        </p>
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={getCSRF() ?? ''}
        />
        <button className="bg-gray-400 p-1" type="submit">
          Release user
        </button>
      </form>
    </div>
  );
}
