import { useCallback, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import useScript, { UseScriptOptions } from './hook';
import useUser from '~/lib/user';
import { _createFormData, fetchEmailSubscribe } from '../util/emailSubscribe';

import { useExperiment } from '~/lib/experiment';
import {
  LOAD_PRIVY_NO_UTM_PARAM,
  SUBSCRIBE_URL,
  MOBILE_PRIVY_POPUP,
} from '~/lib/util/constants';
import { isValidEmail } from '../util';

const HIDE_PRIVY_COOKIE_NAME = 'hide_privy';
const cookies = new Cookies();

export default function usePrivy() {
  const { isLoading, user } = useUser();
  const router = useRouter();
  const [privyShown, setPrivyShown] = useState<boolean>(false);
  const isAnonymous = !isLoading && !user?.id;
  const utmSource = router?.query?.utm_source;
  const allowByQueryParam = !!utmSource && !cookies.get(HIDE_PRIVY_COOKIE_NAME);
  const hideByCookie = cookies.get(HIDE_PRIVY_COOKIE_NAME);
  const { variant: loadNewPrivy } = useExperiment(LOAD_PRIVY_NO_UTM_PARAM);
  const { variant: mobilePrivyPopup } = useExperiment(MOBILE_PRIVY_POPUP);

  // Create global object for Privy experiments. This is needed to enable
  // Privy experiments on the client side, using the privy conditions.
  useEffect(() => {
    if (!mobilePrivyPopup && !MOBILE_PRIVY_POPUP) {
      return;
    }

    // Get variable name. example: EXPERIMENT_VARIABLE_NAME returns
    // "IS_ENABLED_EXPERIMENT_VARIABLE_NAME"
    function getVariableName(variable: any) {
      return 'IS_ENABLED_' + Object.keys(variable)[0];
    }

    // Object used on privy dashboard to show/hide popup
    // example: window.privyExperiments = { IS_ENABLED_EXPERIMENT_VARIABLE_NAME: true }
    window.privyExperiments = {
      [getVariableName({ MOBILE_PRIVY_POPUP })]: mobilePrivyPopup === 'enabled',
    };
  }, [mobilePrivyPopup]);

  // Enroll user to experiment
  function submitEmail() {
    const emailEl = document.querySelector(
      '.privy-email-input',
    ) as HTMLInputElement;
    const email = emailEl?.value;

    if (!isValidEmail(email)) {
      return;
    }

    const formData = _createFormData({});
    formData.append('email', email);

    fetchEmailSubscribe(
      SUBSCRIBE_URL,
      formData,
      cookies.get('csrftoken') || '',
    ).catch((error) => {
      console.error(
        'An error occurred when enrolling user to the experiment',
        error,
      );
    });
  }

  const keyDownEventHandler = useCallback((event: KeyboardEvent) => {
    const input = document.querySelector('.privy-email-input');

    if (event.target === input && event.key === 'Enter') {
      submitEmail();
    }
  }, []);

  const submitEventHandler = useCallback((event: MouseEvent) => {
    const button = document.querySelector('#privy-submit-btn');

    if (event.target === button) {
      submitEmail();
    }
  }, []);

  // Attach event listener to privy submit button and email input
  useEffect(() => {
    // Add event listener
    document.addEventListener('click', submitEventHandler);
    document.addEventListener('keydown', keyDownEventHandler);

    return () => {
      // Remove event listeners
      document.removeEventListener('click', submitEventHandler);
      document.removeEventListener('keydown', keyDownEventHandler);
    };
  }, [submitEventHandler, keyDownEventHandler]);

  useEffect(() => {
    if (privyShown) {
      cookies.set(HIDE_PRIVY_COOKIE_NAME, 'true', {
        maxAge: 60 * 60 * 24,
      });
    }
  }, [privyShown]);

  const initPrivy = useCallback(() => {
    if (typeof window === 'undefined') return;
    window._d_site = 'BB924CED6226F3A9A3091210'; // eslint-disable-line no-underscore-dangle
    window['Privy'] =
      window['Privy'] ||
      function () {
        (window['Privy'].q = window['Privy'].q || []).push(arguments); // eslint-disable-line prefer-rest-params
      };

    setPrivyShown(true);
  }, [setPrivyShown]);

  const destroyPrivy = useCallback(() => {
    if (window._d_site) delete window._d_site; // eslint-disable-line no-underscore-dangle
    document.getElementById('privy-container')?.remove();
    delete window['Privy'];
    delete window['PrivyWidget'];
    window['Privy'] =
      window['Privy'] ||
      function () {
        (window['Privy'].q = window['Privy'].q || []).push(arguments); // eslint-disable-line prefer-rest-params
      };
  }, []);

  const options: UseScriptOptions = useMemo(
    () => ({
      onInit: initPrivy,
      onRemove: destroyPrivy,
    }),
    [initPrivy, destroyPrivy],
  );

  const shouldLoadPrivy =
    isAnonymous &&
    ((loadNewPrivy === 'disabled' && (privyShown || allowByQueryParam)) ||
      (loadNewPrivy === 'enabled' && (privyShown || !hideByCookie)));

  useScript(
    shouldLoadPrivy ? 'https://widget.privy.com/assets/widget.js' : null,
    options,
  );
}
