import { createContext } from 'react';

type EventContextType = {
  subscribe: (event: string, callback: (data?: any) => void) => void;
  unsubscribe: (event: string, callback: (data?: any) => void) => void;
  dispatchEvent: (event: string, data?: any) => void;
};

export const EventContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {},
  dispatchEvent: () => {},
} as EventContextType);
