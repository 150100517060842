import { config } from '@fortawesome/fontawesome-svg-core';
import Cookies from 'universal-cookie';
import { AppProps } from 'next/app';
import { toast } from 'react-hot-toast';

import BaseLayout from '~/layouts/BaseLayout';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '~/styles/globals.scss';
import { SWRConfig } from 'swr';
import { fetchJson } from '~/lib/fetch';
import { EventEmitter } from '~/lib/events';
import { ReactNode, useEffect, useMemo } from 'react';
import Script from 'next/script';
import { Link } from '~/components/global/Link';
import { Toaster } from '~/components/global/Toaster';
import { DEFAULT_SWR_OPTIONS } from '~/constants/global';
import { DoradoProvider } from '@appsumo/dorado-react';
import { useRouter } from 'next/router';
import { getFontFamilyClass } from '~/layouts/util';
import { DefaultSeo } from '~/components/seo/DefaultSeo';
import { gtag } from '~/lib/util/dataLayer';
import { GlobalContext } from '~/contexts/global';
import { useActivity } from '~/hooks/activity';
import { useDealImpressions } from '~/hooks/deal-impression';
import useUserDataLayer from '~/lib/useUserDataLayer';
import useUser from '~/lib/user';
import { ACTIVITY_TYPE } from '~/lib/activity/constants';
import useCampaignConfigs from '~/lib/util/hooks/campaignConfig';

const cookies = new Cookies();

if (typeof window !== 'undefined') {
  const originalFetch = window.fetch;

  window.fetch = function (...args) {
    if (args.length === 2) {
      if (args[1]?.credentials === 'same-origin') {
        const options = { ...args[1] };
        delete options.credentials;
        options.mode = 'cors';
        return originalFetch.call(window, args[0], options);
      }
    }
    return originalFetch.call(window, ...args);
  };
}

config.autoAddCss = false;

// this is a monkey-patch to intercept the _next/data request and prefix the CDN url
const useInterceptNextDataHref = ({ router }: { router: any }) => {
  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      router.pageLoader?.getDataHref
    ) {
      const originalGetDataHref = router.pageLoader.getDataHref;
      router.pageLoader.getDataHref = function (...args: any[]) {
        // call the originalgetDataHref
        const r = originalGetDataHref.call(router.pageLoader, ...args);
        if (r?.startsWith('/_next/data/')) {
          // if its a request to _next/data, prefix the CDN url
          //console.log('GOT IT HERE:', r);
          // TODO: replace CDN url from an environment variable
          // return `https://stage-cdn-${process.env.ENV_NAME}.appsumo.com${r}`;
        }
        return r;
      };
    }
  }, [router]);
};

// any is used here because we don't know exactly what the pageProps will be
interface CustomAppProps extends Omit<AppProps, 'Component'> {
  Component: AppProps['Component'] & {
    getLayout(page: React.ReactNode, pageProps?: any): React.ReactElement;
  };
}

const MyApp = ({ Component, pageProps }: CustomAppProps) => {
  const router = useRouter();
  const { user, messages } = useUser();
  const fontClass = getFontFamilyClass();
  const dealImpressions = useDealImpressions();
  const { addActivity } = useActivity();
  const hasUserDataOnDatalayer = useUserDataLayer();
  const campaigns = useCampaignConfigs();

  const { userState, enrolledExperiments } = pageProps;

  // Set global context
  const globalContextValue = useMemo(
    () => ({
      campaigns,
      dealImpressions,
      experimentVariants: {},
      hasUserDataOnDatalayer,
      userState,
      enrolledExperiments,
    }),
    [
      campaigns,
      dealImpressions,
      hasUserDataOnDatalayer,
      userState,
      enrolledExperiments,
    ],
  );

  useInterceptNextDataHref({
    router,
  });

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const handleRouteChange = (url: string) => {
        gtag('event', 'nextRoute', {
          pageType: 'PageView',
          pageTitle: document.title,
          pageUrl: url,
          pageLocation: window.location.href,
          pagePath: url,
          routeName: router.route || '',
        });
      };

      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router]);

  useEffect(() => {
    if (router.query?.query && user?.id) {
      addActivity({
        actor: `User:${user.id}`,
        verb: ACTIVITY_TYPE.DEAL_SEARCH,
        full_text: router.query.query as string,
      });
    }
    // disabling eslint here, adding `addActivity` causes a ton of re-renders + more events than needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query?.query, user?.id]);

  // add font variables to body
  useEffect(() => {
    document.body.classList.add(...fontClass.split(' '));
  }, [fontClass]);

  useEffect(() => {
    Object.keys(router.query).forEach((key) => {
      if (key.startsWith('utm_'))
        cookies.set(key, router.query[key], {
          path: '/',
          maxAge: 60 * 60 * 24, // 1 day
        });
    });
  }, [router.query]);

  // Display any changes to the django session messages
  useEffect(() => {
    messages?.forEach(({ tags, message }) => {
      switch (tags) {
        case 'success':
          toast.success(message);
          break;
        case 'error':
          toast.error(message);
          break;
        default:
          toast(message);
      }
    });
  }, [messages]);

  function getLayout(page: ReactNode) {
    if (Component.getLayout) {
      return Component.getLayout(page, pageProps);
    }

    return (
      <BaseLayout className={`flex min-h-screen flex-col font-sans`}>
        {page}
      </BaseLayout>
    );
  }

  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-K87H2BB');
      `}
      </Script>
      <Script id="gtag-setup" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
        `}
      </Script>
      <SWRConfig
        // Set defaults for SWR to match useSWRImmutable
        // https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
        value={{
          ...DEFAULT_SWR_OPTIONS,
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          fetcher: fetchJson,
        }}
      >
        <EventEmitter>
          <DoradoProvider value={{ linkComponent: Link as any }}>
            <DefaultSeo />
            <Toaster />
            <GlobalContext.Provider value={globalContextValue}>
              {getLayout(<Component {...pageProps} />)}
            </GlobalContext.Provider>
          </DoradoProvider>
        </EventEmitter>
      </SWRConfig>
    </>
  );
};

export default MyApp;
