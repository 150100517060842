import { useCallback, useMemo, useReducer, useRef } from 'react';
import { EventContext } from './EventContext';
import { ReactNode } from 'react';

type EventEmitterAction = {
  type: 'subscribe' | 'unsubscribe';
  event: string;
  callback: any;
};

type EventEmitterStore = {
  [key: string]: void[];
};

export const EventEmitter = ({ children }: { children: ReactNode }) => {
  const [subscribers, dispatch] = useReducer(
    (state: EventEmitterStore, action: EventEmitterAction) => {
      const { type, event } = action;
      switch (type) {
        case 'subscribe': {
          const { callback } = action;
          if (event in state) {
            if (state[event].includes(callback)) {
              return state;
            }
            return { ...state, [event]: [...state[event], callback] };
          }
          return { ...state, [event]: [callback] };
        }

        case 'unsubscribe': {
          const { callback } = action;
          if (event in state && state[event].includes(callback)) {
            return {
              ...state,
              [event]: [...state[event].filter((cb: any) => cb !== callback)],
            };
          }
          return state;
        }

        default:
          throw new Error();
      }
    },
    {},
    () => ({}),
  );

  const subscribersRef = useRef({});
  subscribersRef.current = useMemo(() => subscribers, [subscribers]);

  const subscribe = useCallback(
    (event: string, callback: any) => {
      dispatch({ type: 'subscribe', event, callback });
    },
    [dispatch],
  );

  const unsubscribe = useCallback(
    (event: string, callback: any) => {
      dispatch({ type: 'unsubscribe', event, callback });
    },
    [dispatch],
  );

  const dispatchEvent = useCallback(
    (event: string, data: any) => {
      if (event in subscribersRef.current) {
        (subscribersRef.current as EventEmitterStore)[event].forEach(
          (cb: any) => cb(data),
        );
      }
    },
    [subscribersRef],
  );

  const eventPack = useMemo(
    () => ({ subscribe, unsubscribe, dispatchEvent }),
    [subscribe, unsubscribe, dispatchEvent],
  );

  return (
    <EventContext.Provider value={eventPack}>{children}</EventContext.Provider>
  );
};
