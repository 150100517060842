import { useRouter } from 'next/router';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from '~/components/global/Link';
import { NavItemProps } from '~/types/layout';

export default function NavGroups({
  navItems,
}: {
  navItems: Array<NavItemProps>;
}) {
  const router = useRouter();
  const { asPath } = router;

  return (
    <div className="w-full grow md:w-auto">
      <ul
        className="flex flex-col md:h-full md:flex-row md:items-center"
        data-testid="nav-bar-ul"
      >
        {navItems.map((item: NavItemProps) => (
          <li
            key={item.href}
            className="flex md:mr-8 md:h-full md:shrink-0 md:items-center"
          >
            <Link
              key={item.href}
              href={item.href}
              className={clsx(
                'flex-1 font-header text-xl font-semibold',
                'md:font-sans md:text-base md:font-normal',
                'border-transparent py-2 leading-6 md:border-b-2 md:py-4 md:hover:border-bolt',
                { 'md:border-bolt': asPath.indexOf(item.href) === 0 },
              )}
            >
              {item.icon && (
                <FontAwesomeIcon
                  icon={item.icon.src}
                  className={item.icon.className}
                />
              )}
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
