import { memo } from 'react';
import Image from 'next/image';

import { Link } from '~/components/global/Link';
import { CampaignConfigNavPill } from '~/types/campaign';

export default memo(function CampaignNavPill({
  config,
}: {
  config: CampaignConfigNavPill;
}) {
  return (
    <Link
      href={config.link}
      className="flex h-10 items-center justify-center rounded-[40px] px-5 text-midnight md:h-8"
      style={{ backgroundColor: config.background_color }}
      data-testid="campaign-pill"
    >
      {config.icon && (
        <Image
          src={config.icon}
          alt="AppSumo Campaign Button"
          className="mr-[5px] align-middle"
          width={32}
          height={32}
          unoptimized
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: config.text }} />
    </Link>
  );
});
