import { useEffect, useRef } from 'react';

export function usePrevious<T>(value: T): T | undefined {
  // Used "any" because of the "not assignable" error
  // (we won't know the type of the value here)
  const ref = useRef(null as any);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

// Because we are using reactStrictMode, we render components/pages twice to ensure they are safe to render
// https://react.dev/blog/2022/03/29/react-v18#new-strict-mode-behaviors
// This should only happen in develop mode, but to ensure we only this given effect once, we should limit with a ref
export function useMountEffect(callback: () => void, deps: Array<any> = []) {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) return;

    callback();
    hasMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

// Return true if the value has changed (shallow comparison)
// (use "any" because we won't know the type of the value here)
export function useHasValueChanged<T>(value: T): any {
  const previousValue = usePrevious<T>(value);

  return {
    hasChanged: previousValue !== value,
    previousValue,
  };
}
