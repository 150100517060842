import { DropdownOptionProps } from '@appsumo/dorado-react';

import { MAX_NUM_SAVED_DEALS } from './constants';
import { SavedDeal, SaveForLaterItems, CartItemProps } from '~/types/cart';

// gets the MAX_NUM_SAVED_DEALS latest non-expired saved deals -- we're defaulting to max 5 deals
//
// note: we run a task to clear expired deals from saved for later, this is more of a
//       double check + handle anon wishlists since they're tied to session not Wishlist
export function getAvailableSavedDeals(items?: SaveForLaterItems): SavedDeal[] {
  return (
    items?.deals
      .filter(
        (deal: SavedDeal) =>
          !deal.dates?.end_date || new Date() < new Date(deal.dates.end_date),
      )
      ?.slice(0, MAX_NUM_SAVED_DEALS) || []
  );
}

export function getCurrentPlan(
  dealPlan: CartItemProps['deal_plan'],
  dealPlanList: CartItemProps['deal']['plans'],
): DropdownOptionProps {
  const defaultPlan = { value: '', label: '' };

  if (!dealPlanList || !dealPlanList) return defaultPlan;

  const plan = dealPlanList.find((p) => p.id === dealPlan.id);

  if (!plan) return defaultPlan;

  return {
    value: plan.id,
    label: plan.public_name,
  };
}
