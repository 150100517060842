import { useContext, useEffect } from 'react';
import { EventContext } from './EventContext';

// Using "any" as the type for data is intentional, it can be "anything"
export function useEvent(event: string, callback: (data?: any) => void) {
  const { subscribe, unsubscribe } = useContext(EventContext);

  useEffect(() => {
    subscribe(event, callback);

    return () => unsubscribe(event, callback);
  }, [subscribe, unsubscribe, event, callback]);
}
