import useSWR, { mutate } from 'swr';

import { CART_ITEMS_URL } from '~/constants/cart';
import { DEFAULT_SWR_OPTIONS } from '~/constants/global';
import { fetchData, fetchJson } from '~/lib/fetch';
import useUser from '~/lib/user';
import { useEventDispatch } from '~/lib/events';
import {
  CartProps,
  SaveForLaterItems,
  UseCartProps,
  ToggleCreditType,
  CartItemProps,
} from '~/types/cart';
import { Deal } from '~/types/deal';
import { CHECKOUT_ERRORS } from '~/constants/checkout';
import {
  APPSUMO_PLUS_DEAL_ID,
  APPSUMO_PLUS_DEAL_PLAN_ID,
} from '~/lib/util/constants';

export interface LineItemAddFunction {
  (
    dealId: number,
    planId?: number | null,
    couponCode?: string | null,
    quantity?: number | null,
    noRedirect?: boolean,
  ): void;
}

export function useCart(
  fallbackData?: CartProps,
  omitUserMutate: boolean = false,
): UseCartProps {
  // We do want SWR to revalidate since users update their cart often on different browser tabs/windows
  // Some places this hook is called outside SWRConfig scope (ex. useCampaignConfigs)
  // Setting the options manually
  const { data: cart, mutate: mutateItems } = useSWR(
    CART_ITEMS_URL,
    fetchJson,
    {
      ...DEFAULT_SWR_OPTIONS,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      fallbackData,
    },
  );
  // On the cart page, there is no reason to re-fetch the session.  The useCart hook is used throughout
  // the app and testing is needed on the reaching effects of removing the user mutate function.
  // This is being adding as a temporary measure until all functions that mutate the user have been tested
  const { mutate: mutateUserFunc } = useUser();
  const mutateUser = omitUserMutate ? () => {} : mutateUserFunc;
  const dispatchEvent = useEventDispatch();

  const lineItemAdd = async (
    dealId: number,
    planId?: number | null,
    couponCode?: string | null,
    quantity?: number | null,
    noRedirect?: boolean,
  ) => {
    dispatchEvent('cart:adding');
    const couponParam = `?coupon=${couponCode}`;

    return fetchJson(`/api/v2/cart/add/${couponCode ? couponParam : ''}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({
        deal_id: dealId,
        plan_id: planId,
        coupon_code: couponCode,
        quantity: quantity,
      }),
    }).then((/*data*/) => {
      // TODO: revert back when endpoint is fixed
      return Promise.all([
        mutateUser(),
        mutateItems(),
        noRedirect ? Promise.resolve() : dispatchEvent('cart:added'),
      ]);

      // return Promise.resolve(data);
    });
  };

  const lineItemUpdate = async (
    dealId: number,
    quantity: number,
  ): Promise<any> => {
    dispatchEvent('cart:lineItemUpdate:loading', true);
    return fetchJson('/api/v2/cart/modify/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ deal_id: dealId, quantity }),
    })
      .then((response) => Promise.all([mutateUser(), mutateItems(), response]))
      .finally(() => dispatchEvent('cart:lineItemUpdate:loading', false));
  };

  const lineItemUpdatePlan = async (dealId: number, planId: number) => {
    return fetchJson('/api/v2/cart/modify/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ deal_id: dealId, plan_id: planId }),
    }).then(() => {
      return Promise.all([mutateUser(), mutateItems()]);
    });
  };

  const lineItemRemove = async (dealId: number) => {
    dispatchEvent('cart:lineItemRemove:loading', true);
    return fetchJson('/api/v2/cart/remove/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ deal_id: dealId }),
    })
      .then(() => Promise.all([mutateUser(), mutateItems()]))
      .catch(() => Promise.resolve())
      .finally(() => dispatchEvent('cart:lineItemRemove:loading', false));
  };

  const setLicensePlan = async (
    dealId: number | string,
    planId: number | string,
  ) => {
    dispatchEvent('cart:setLicensePlan:loading', true);

    const [, items] = await lineItemUpdatePlan(
      dealId as number,
      planId as number,
    )
      .catch(() => Promise.resolve([null, null]))
      .finally(() => dispatchEvent('cart:setLicensePlan:loading', false));

    return items;
  };

  const toggleCredits = async (useCredit: boolean): ToggleCreditType => {
    const formData = new FormData();
    formData.append('use_credit', useCredit ? 'True' : 'False');
    dispatchEvent('credits:loading');

    return (
      fetchData('/cart/credit/', {
        method: 'POST',
        include: 'credentials',
        body: formData,
      })
        .then(() => Promise.all([mutateUser(), mutateItems()]))
        // Send back undefined to let the caller know that the request failed and to show an error
        // The caller destructures the response into two variables (only need the later of the two)
        .catch(() => Promise.all([undefined, undefined]))
        .finally(() => dispatchEvent('credits:reset'))
    );
  };

  // @return: Promise<string> of error message (empty string for success)
  const toggleCoupon = async (
    couponCode: string,
    apply: boolean,
  ): Promise<string> => {
    dispatchEvent('coupon:loading');

    const formData = new FormData();
    formData.append('code', couponCode);

    let err = '';

    try {
      await fetchData(`/cart/ajax/${apply ? '' : 'remove_'}coupon/`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      });
      await Promise.all([mutateUser(), mutateItems()]);
    } catch (e: any) {
      const { code } = await e.response.json();
      err = code?.length ? code[0] : CHECKOUT_ERRORS.COUPON;
    }

    dispatchEvent('coupon:lastAdded', apply ? couponCode : '');
    dispatchEvent('coupon:reset');
    return err;
  };

  const applyCoupon = async (couponCode: string) => {
    return toggleCoupon(couponCode, true);
  };

  const unapplyCoupon = async (couponCode: string) => {
    return toggleCoupon(couponCode, false);
  };

  // Add plus to cart
  const addPlusToCart = async () => {
    return fetchJson('/api/v2/cart/add/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({
        deal_id: APPSUMO_PLUS_DEAL_ID,
        plan_id: APPSUMO_PLUS_DEAL_PLAN_ID,
        quantity: 1,
      }),
    }).then(() => Promise.all([mutateUser(), mutateItems()]));
  };

  const addUpsellToCart = async (cart?: CartProps) => {
    // cart is not set yet once this function is created
    if (!cart?.upsell_deal) return;

    if (cart?.upsell_deal?.use_licensing) {
      const itemsUpdated = await setLicensePlan(
        cart.upsell_deal.id,
        cart.upsell_deal.preferred_plan.id,
      );

      // Update the dropdown if the upsell plan is in the cart
      if (
        itemsUpdated?.cart_items.some(
          (item: CartItemProps) =>
            item?.deal_plan?.id === cart.upsell_deal?.preferred_plan.id,
        )
      ) {
        dispatchEvent('cart:update_plan_type', {
          value: cart.upsell_deal?.preferred_plan.id,
          label: cart.upsell_deal?.preferred_plan.public_name,
        });
      }
    } else {
      // Update the quantity once the upsell plan was added to the cart
      const response = await lineItemUpdate(
        cart.upsell_deal.id,
        cart?.upsell_deal?.preferred_plan.codes,
      );
      // [2] is the response from the API ([0] is user mutate and [1] is cart mutate)
      if (response?.[2]?.success) {
        dispatchEvent('cart:update_code_quantity', {
          dealId: cart.upsell_deal?.id,
          value: cart.upsell_deal?.preferred_plan.codes,
        });
      }
    }
  };

  const addUpsellPlanToCart = async (deal: Deal) => {
    if (!deal?.preferred_plan?.id) return;

    if (deal.use_licensing) {
      await setLicensePlan(deal.id, deal.preferred_plan.id);
    } else {
      await lineItemUpdate(deal.id, deal.preferred_plan.codes);
    }
  };

  return {
    cart,
    lineItemAdd,
    lineItemUpdate,
    lineItemUpdatePlan,
    lineItemRemove,
    toggleCredits,
    applyCoupon,
    unapplyCoupon,
    mutateItems,
    setLicensePlan,
    addPlusToCart,
    addUpsellToCart,
    addUpsellPlanToCart,
  };
}

interface useSavedCartType {
  items: SaveForLaterItems;
  saveDeal: (dealId: number) => Promise<void | [void, any, any]>; // "any" is what swr says it is
  removeSavedDeal: (wishlistSlug: string, dealId: number) => void;
  moveToCart: (dealId: number) => void;
  moveAllToCart: (wishlistId: number) => Promise<any>;
}

export function useSavedCart(
  fallbackData?: SaveForLaterItems,
): useSavedCartType {
  // We do want SWR to revalidate since users update their cart often on different browser tabs/windows
  const { data: items, mutate: mutateItems } = useSWR(
    '/api/wishlists/saved/items/',
    fetchJson,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      fallbackData,
    },
  );
  const { mutate: mutateUser } = useUser();
  const dispatchEvent = useEventDispatch();

  const saveDeal = (dealId: number) => {
    dispatchEvent('cart:saveDeal:loading', true);
    return fetchJson('/api/wishlists/saved/save_deal/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ deal_id: dealId }),
    })
      .then(() =>
        Promise.all([mutateUser(), mutateItems(), mutate(CART_ITEMS_URL)]),
      )
      .catch(() => Promise.resolve())
      .finally(() => dispatchEvent('cart:saveDeal:loading', false));
  };

  const removeSavedDeal = (wishlistSlug: string, dealId: number) => {
    fetchJson(`/api/wishlists/${wishlistSlug}/remove_deal/`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ deal_id: dealId }),
    }).then(() => Promise.all([mutateUser(), mutateItems()]));
  };

  const moveToCart = (dealId: number) => {
    dispatchEvent('cart:moveToCart:loading', true);
    fetchJson('/api/wishlists/saved/move_to_cart/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ deal_id: dealId }),
    })
      .then(() =>
        Promise.all([mutateUser(), mutateItems(), mutate(CART_ITEMS_URL)]),
      )
      .finally(() => dispatchEvent('cart:moveToCart:loading', false));
  };

  const moveAllToCart = (wishlistId: number): Promise<any> => {
    return fetchJson(`/api/wishlists/${wishlistId}/add_all_to_cart/`, {
      method: 'POST',
      include: 'credentials',
    }).then(() =>
      Promise.all([mutateUser(), mutateItems(), mutate(CART_ITEMS_URL)]),
    );
  };

  return {
    items,
    saveDeal,
    removeSavedDeal,
    moveToCart,
    moveAllToCart,
  };
}
