import { Deal } from '~/types/deal';
import { DISCUSSION } from './constants';

export type DiscussionSort =
  | 'latest'
  | 'date'
  | 'newest'
  | 'oldest'
  | 'helpful'
  | 'rating:asc'
  | 'rating:desc';

export interface DiscussionSortOption {
  value: DiscussionSort;
  label: string;
}

export type DiscussionFilter =
  | 'all'
  | 'unresolved'
  | 'unapproved'
  | 'needs_reply';

export type TacoRatingFilter = 'all' | '1' | '2' | '3' | '4' | '5';

export interface TacoRatingFilterOption {
  value: TacoRatingFilter;
  label: string;
}

export const ANSWER_TYPE_PARTNER = 'partner';
export const ANSWER_TYPE_STAFF = 'staff';

export type CommentAnswerType =
  | typeof ANSWER_TYPE_PARTNER
  | typeof ANSWER_TYPE_STAFF
  | null;

export interface DiscussionFilterOption {
  value: DiscussionFilter;
  label: string;
}

export type CommentUser = {
  id: number;
  avatar: string;
  username: string;
  date_joined: string;
  deals_purchased: number;
  comment_whitelist: boolean;
  comment_blacklist: boolean;
  has_briefcase?: boolean;
  has_plus?: boolean;
};

export type QuestionV2 = {
  id: number;
  display_path: string;
  parent_id?: number;
  deal_id: number;
  level: number;
  href: string;
  user: CommentUser;
  created: string;
  modified: string;
  purchased: boolean;
  title: string;
  comment: string;
  approved: boolean;
  resolved: boolean;
  up_votes: number;
  down_votes: number;
  user_vote: number;
  children?: CommentV2[];
  status: DiscussionStatus;
  answer_type?: CommentAnswerType;
};

export interface IDiscussionUserInfoProps {
  id: number;
  username: string;
  avatar: string;
  isVerified: boolean;
  isPlus: boolean;
  isFounder?: boolean;
  userNamePadding?: boolean;
  commentDate?: Date;
  isReply?: boolean; // if reply, show only small avatar
  // only for reviews
  dealsBought: number | null;
  memberSinceDate: Date | null;
}

export interface IQuestionThreadProps {
  questionId: number;
  questionUrl: string;
  comments?: IQuestionCommentProps[];
  onReply?: (comment: QuestionV2) => void;
}

export interface IQuestionCommentProps {
  id: number;
  text: string;
  level: number;
  status: DiscussionStatus;
  userInfo: IDiscussionUserInfoProps;
  comments?: IQuestionCommentProps[];
  isNew?: boolean;

  // Very first parent question id (root question)
  questionId: number;
  deletedCallback?: () => void;
}

export type ReviewV2 = {
  id: number;
  display_path: string;
  parent_id?: number;
  deal_id: number;
  level: number;
  href: string;
  user: CommentUser;
  created: string;
  modified: string;
  purchased: boolean;
  title: string;
  comment: string;
  approved: boolean;
  resolved: boolean;
  rating: number;
  up_votes: number;
  user_vote: number;
  down_votes: number;
  would_recommend: boolean;
  review_dimensions: {
    slug: string;
    name: string;
    rating: number;
  }[];
  children?: CommentV2[];
  status: DiscussionStatus;
  answer_type?: CommentAnswerType;
};

export type CommentV2 = QuestionV2 | ReviewV2;

export interface DiscussionStatusTag {
  text: string;
  className: string;
}

export type DiscussionType =
  | typeof DISCUSSION.QUESTIONS
  | typeof DISCUSSION.REVIEWS;

export interface QuestionPageProps {
  deal: Deal;
  discussion: {
    comments: [QuestionV2];
    meta: Record<string, any>;
  };
  discussionId: number;
}

export interface ReviewPageProps {
  deal: Deal;
  discussion: {
    comments: [ReviewV2];
    meta: Record<string, any>;
  };
  discussionId: number;
}

export interface DiscussionQuestion {
  comments: [QuestionV2];
  meta: Record<string, any>;
  redirect?: string;
}

export interface DiscussionReview {
  comments: [ReviewV2];
  meta: Record<string, any>;
  redirect?: string;
}

export type DiscussionComment = DiscussionQuestion | DiscussionReview;

export const DISCUSSION_STATUS_UNMODERATED = 'unmoderated';
export const DISCUSSION_STATUS_APPROVED = 'approved';
export const DISCUSSION_STATUS_REJECTED = 'rejected';

export type DiscussionStatus =
  | typeof DISCUSSION_STATUS_UNMODERATED
  | typeof DISCUSSION_STATUS_APPROVED
  | typeof DISCUSSION_STATUS_REJECTED;
