import React, { useEffect } from 'react';
import Image from 'next/image';

import useUser from '~/lib/user';

import { Link } from '~/components/global/Link';
import logo from '~/public/as-appsumo-logo-light.svg';
import youtube from '~/public/youtube-icon.svg';
import facebook from '~/public/facebook-icon.svg';
import instagram from '~/public/instagram-icon.svg';
import tiktok from '~/public/tiktok-icon.svg';
import xLogo from '~/public/x-logo.png';

type MenuLinks = {
  [key: string]: MenuLink;
};

export type MenuLink = {
  href: string;
  label: string;
  target: '_self' | '_blank';
};

export type SocialLink = {
  href: string;
  label: string;
  icon: any;
  target: '_self' | '_blank';
  noStyle?: boolean;
};

const accountMenu: MenuLinks = {
  account: { href: '/account/profile/', label: 'Profile', target: '_self' },
  plus: { href: '/plus', label: 'Join Plus', target: '_self' },
  help: {
    href: 'https://help.appsumo.com',
    label: 'Help center',
    target: '_blank',
  },
  terms: { href: '/terms-of-use/', label: 'Terms', target: '_blank' },
  privacy: { href: '/privacy/', label: 'Privacy', target: '_self' },
};

const sumoMenu: MenuLinks = {
  about: { href: '/about', label: 'About', target: '_self' },
  blog: { href: 'https://blog.appsumo.com/', label: 'Blog', target: '_blank' },
  careers: { href: '/careers', label: 'Careers', target: '_self' },
  affiliates: {
    href: 'https://go.appsumo.com/become-appsumo-affiliate/',
    label: 'Affiliates',
    target: '_self',
  },
};

const sellMenu: MenuLinks = {
  select: { href: '/select', label: 'AppSumo Select', target: '_blank' },
  sell: {
    href: 'https://sell.appsumo.com/',
    label: 'List your own product',
    target: '_blank',
  },
  caseStudies: {
    href: '/case-studies',
    label: 'Case studies',
    target: '_self',
  },
};

const learnMenu: MenuLinks = {
  howTo: {
    href: 'https://appsumo.com/content/how-to-start-an-online-business',
    label: 'How to start an online business',
    target: '_blank',
  },
  creatorEconomy: {
    href: 'https://appsumo.com/content/what-is-the-creator-economy',
    label: 'What is the creator economy?',
    target: '_blank',
  },
};

const socialMenu: SocialLink[] = [
  {
    icon: youtube,
    label: 'YouTube',
    target: '_blank',
    href: 'https://www.youtube.com/channel/UCRrAZbOmqB3pX7dWHC_-wEQ',
  },
  {
    icon: facebook,
    label: 'Facebook',
    target: '_blank',
    href: 'https://www.facebook.com/appsumo/',
  },
  {
    icon: instagram,
    label: 'Instagram',
    target: '_blank',
    href: 'https://www.instagram.com/appsumo/',
  },
  {
    icon: xLogo,
    label: 'Twitter',
    target: '_blank',
    href: 'https://twitter.com/AppSumo/',
    noStyle: true,
  },
  {
    icon: tiktok,
    label: 'TikTok',
    target: '_blank',
    href: 'https://www.tiktok.com/@appsumo',
  },
];

function Menu({ items, title }: { title: string; items: MenuLinks }) {
  return (
    <div>
      <div className="font-header text-xl font-semibold">{title}</div>
      <ul className="text-sm">
        {Object.keys(items).map((key: string) => {
          const item: MenuLink = items[key];
          return (
            <li key={item.href} className="my-4 sm:my-2">
              <Link
                href={item.href}
                target={item.target}
                className="text-white hover:text-opacity-70 focus:text-opacity-70"
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

// eslint-disable-next-line react/display-name
const MemoizedMenu = React.memo(
  ({
    accountMenu,
    sumoMenu,
    sellMenu,
    learnMenu,
    hasPlus,
  }: {
    accountMenu: MenuLinks;
    sumoMenu: MenuLinks;
    sellMenu: MenuLinks;
    learnMenu: MenuLinks;
    hasPlus: boolean;
  }) => {
    const [accountMenuClone, setAccountMenuClone] = React.useState(accountMenu);

    useEffect(() => {
      if (Object.getOwnPropertyDescriptor(accountMenuClone, 'plus')) {
        accountMenuClone.plus.href = hasPlus ? '/account/plus/' : '/plus/';
        accountMenuClone.plus.label = hasPlus ? 'Plus' : 'Join Plus';

        setAccountMenuClone({ ...accountMenuClone });
      }
    }, [hasPlus]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div className="grid grid-cols-2 gap-y-5 md:grow md:grid-cols-4">
        <Menu items={accountMenuClone} title="Account" />
        <Menu items={sumoMenu} title="AppSumo" />
        <Menu items={sellMenu} title="Sell" />
        <Menu items={learnMenu} title="Learn" />
      </div>
    );
  },
);

// eslint-disable-next-line react/display-name
const MemoizedSocialLinks = React.memo(
  ({ className }: { className?: string }) => {
    return (
      <ul className={`flex items-center ${className ? className : ''}`}>
        {socialMenu.map((item: SocialLink) => (
          <li key={item.href}>
            <a href={item.href} target={item.target}>
              <span className="sr-only">{item.label}</span>
              <Image
                src={item.icon}
                alt=""
                height={20}
                style={
                  item.noStyle
                    ? {}
                    : {
                        filter:
                          'invert(1) sepia(1) saturate(5) hue-rotate(175deg)',
                      }
                }
              />
            </a>
          </li>
        ))}
      </ul>
    );
  },
);

export function Footer() {
  const { user } = useUser();

  return (
    <footer className="bg-black-pearl py-10 text-white">
      <div className="mx-auto px-10 lg:max-w-7xl">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-10 lg:basis-1/4">
            <Link href="/">
              <Image alt="AppSumo" src={logo} width="150" height="23" />
            </Link>
            <p className="mt-4 text-sm leading-relaxed md:mb-5 md:max-w-[190px]">
              Part of the Sumo family
              <br />
              with <a href="https://tidycal.com/">TidyCal</a>
              {', '}
              <a href="https://fivetaco.com/">FiveTaco</a>
              {', '} and <a href="https://sendfox.com/">SendFox</a>.
            </p>
            <MemoizedSocialLinks className="hidden gap-x-5 md:flex" />
          </div>
          <MemoizedMenu
            accountMenu={accountMenu}
            sumoMenu={sumoMenu}
            sellMenu={sellMenu}
            learnMenu={learnMenu}
            hasPlus={!!user?.has_plus}
          />
        </div>
        <MemoizedSocialLinks className="my-10 w-full justify-between md:hidden" />
      </div>
    </footer>
  );
}
