import Image from 'next/image';
import { Link } from '~/components/global/Link';
import icon from '~/public/shopping-bag-confetti.svg';

export default function PartnerPortal() {
  return (
    <Link
      className="whitespace-nowrap leading-[24px] hover:underline max-md:mb-6 max-md:flex max-md:gap-x-2 max-md:rounded max-md:bg-green-900 max-md:py-2 max-md:px-4 max-md:text-white"
      href="/partners/overview/"
    >
      <Image
        src={icon}
        alt="Partner Portal"
        height="44"
        className="md:hidden"
      />
      <div className="max-md:flex max-md:flex-col">
        <span>Partner Portal</span>
        <span className="md:hidden">Manage your digital products</span>
      </div>
    </Link>
  );
}
