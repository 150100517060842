import { useRouter } from 'next/router';
import {
  Fragment,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useSWR from 'swr';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { useEventDispatch } from '~/lib/events';
import { fetchJson } from '~/lib/fetch';
import { useHasValueChanged } from '~/hooks/utils';

import type { DealSkuCardType } from '~/components/sku/types';

export default function SearchInput({
  isCollectionBrowsePage,
  navGroupUrlList,
}: {
  isCollectionBrowsePage?: boolean;
  navGroupUrlList?: Array<string>;
}) {
  const dispatchEvent = useEventDispatch();
  const [search, setSearch] = useState<string>('');
  const [finalSearch, setFinalSearch] = useState<string>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const isTrending = !search;
  const router = useRouter();
  const currentUrl = router?.asPath.split('?')[0];
  const { hasChanged: hasUrlChanged, previousValue: previousUrl } =
    useHasValueChanged<string>(currentUrl);
  const searchURL = finalSearch
    ? `/api/v2/deals/esbrowse/?status=all&sort=recommended&query=${finalSearch}&per_page=5&autocomplete=1`
    : `/api/v2/deals/esbrowse/?status=current&sort=recommended&toptrending=1&per_page=5`;
  const { data } = useSWR(() => (isFocus ? searchURL : null), fetchJson, {
    // Reset to default SWR API values
    refreshInterval: 1000 * 60, // Refresh data after 1 minute
  });

  // Adds the url query to the search input on page "load" (there is a delay unfortunately)
  // We only need to do this once, so ignore changes to the query
  useEffect(() => {
    if (!router.isReady || !router.query?.query) return;
    setSearch(router.query.query as string);
  }, [router.isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  // Remove the search query when the url changes to a nav group url or is not a collection browse page
  // Keep the query if going from /search to /software by clicking the shop catgegories filter
  useEffect(() => {
    if (!hasUrlChanged || !navGroupUrlList || !isCollectionBrowsePage) return;

    const isUrlInNavList = navGroupUrlList.some((url) => url === currentUrl);

    if (
      (isUrlInNavList && previousUrl !== '/search/') ||
      (isUrlInNavList && !router.query?.query)
    ) {
      setSearch('');
    }
  }, [
    currentUrl,
    hasUrlChanged,
    isCollectionBrowsePage,
    navGroupUrlList,
    search,
    setSearch,
    previousUrl,
    router.query?.query,
  ]);

  const deals: DealSkuCardType[] = data?.deals || [];
  const handleClearSearch = useCallback(async () => {
    // Clear the search term and if on the collections page, clear the results
    if (search) {
      setSearch('');
      setFinalSearch('');
      inputRef?.current?.focus();

      // There are previous search results
      if (isCollectionBrowsePage && router?.query?.query) {
        await router.push('/search/');
      } else if (router?.query?.query) {
        delete router.query.query;
        await router.push(router);
      }
    }
  }, [inputRef, search, router, isCollectionBrowsePage]);

  const handleClickDeal = (deal: DealSkuCardType) => {
    window.location.href = `/products/${deal.slug}/?query=${deal.public_name}`;
  };

  const onBlurParentContainer = (event: React.FocusEvent<HTMLElement>) => {
    const { currentTarget } = event;

    requestAnimationFrame(() => {
      // if focus is not on child, unfocus
      if (!currentTarget.contains(document.activeElement)) {
        setIsFocus(false);
      }
    });

    dispatchEvent('pdp:toggleBuyButton', false);
  };

  const handleSubmitSearch = async () => {
    if (focusedItemIndex > -1) {
      handleClickDeal(deals[focusedItemIndex]);
    } else {
      // go to the search page
      // Per https://appsumo.atlassian.net/browse/ENG-2342, do not search within the group
      await router.push(`/search/?query=${search}`);
    }

    inputRef?.current?.blur();
    searchButtonRef?.current?.blur();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    let newIndex = -1;
    if (event.code === 'ArrowDown' || event.key === 'ArrowDown') {
      event.preventDefault();
      // if last item, go to first item
      if (focusedItemIndex === null || focusedItemIndex === deals.length - 1) {
        newIndex = 0;
      } else {
        newIndex = focusedItemIndex + 1;
      }
    } else if (event.code === 'ArrowUp' || event.key === 'ArrowUp') {
      event.preventDefault();

      // if first item, go to last item
      if (focusedItemIndex === null || focusedItemIndex === 0) {
        newIndex = deals.length - 1;
      } else {
        newIndex = focusedItemIndex - 1;
      }
    } else if (event.code === 'Enter' || event.key === 'Enter') {
      event.preventDefault();
      handleSubmitSearch();
    }
    setFocusedItemIndex(newIndex);

    if (newIndex !== -1) {
      setSearch(deals[newIndex].public_name);
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    // If key is not arrow up, down, or enter, update the search
    if (
      event.code !== 'ArrowDown' &&
      event.code !== 'ArrowUp' &&
      event.code !== 'Enter' &&
      event.key !== 'ArrowDown' &&
      event.key !== 'ArrowUp' &&
      event.key !== 'Enter'
    ) {
      setFinalSearch(search);
    }
  };
  return (
    <div className="order-4 col-[full] max-md:pb-[16px] md:order-none md:col-auto md:px-[32px] xl:pl-[97px]">
      <Menu>
        {() => (
          <div
            className="relative flex rounded-[30px] border border-grace bg-iceberg px-1 py-[1px] shadow-lg shadow-black/10 focus-within:border-2 focus-within:border-bolt focus-within:px-[3px] focus-within:py-0.5"
            onFocus={() => {
              setIsFocus(true);
              dispatchEvent('pdp:toggleBuyButton', true);
            }}
            onBlur={onBlurParentContainer}
          >
            <input
              type="text"
              className="mx-3 my-1 w-full border-none bg-iceberg text-black-pearl outline-none"
              placeholder="Search products"
              autoComplete="off"
              value={search}
              ref={inputRef}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              data-testid="search-bar-input"
            />
            <div className="flex items-center gap-x-1">
              {!isTrending && (
                <>
                  <button
                    className="flex items-center justify-center px-2 py-1"
                    onClick={handleClearSearch}
                  >
                    <span className="sr-only">Clear search</span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-xl font-bold text-grace"
                      data-testid="search-bar-clear-icon"
                    />
                  </button>
                  <div className="border-r border-grace">&nbsp;</div>
                </>
              )}
              <button
                className="flex items-center justify-center rounded-[30px] px-4 py-1 transition-colors duration-300 ease-in-out hover:bg-lemon"
                onClick={handleSubmitSearch}
                ref={searchButtonRef}
              >
                <span className="sr-only">Submit search</span>
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-xl font-bold text-grace"
                  data-testid="search-bar-glass-icon"
                />
              </button>
            </div>
            <Transition
              as={Fragment}
              show={isFocus && deals?.length > 0}
              enter="transition duration-300 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition duration-300 ease-out"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Menu.Items className="absolute mt-10 flex w-full flex-col border border-gray-300 bg-white text-black-pearl outline-none">
                <div className="px-4 py-2 text-sm font-bold">
                  <FontAwesomeIcon
                    icon={isTrending ? faChartLine : faSearch}
                    className="mr-2"
                  />
                  {isTrending ? 'Trending Searches' : 'Suggested Searches'}
                </div>
                {deals.map((deal, index) => (
                  <Menu.Item key={deal.id}>
                    <button
                      onClick={() => handleClickDeal(deal)}
                      className={`cursor-pointer px-4 py-2 text-left text-sm hover:bg-iceberg ${
                        index === focusedItemIndex && 'bg-iceberg'
                      }`}
                    >
                      <strong>{deal.public_name}</strong>
                      {deal?.attributes?.category &&
                        deal?.attributes?.category.length > 0 && (
                          <span className="text-xs">
                            &nbsp;in {deal?.attributes.category[0]}
                          </span>
                        )}
                    </button>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  );
}
