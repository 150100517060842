import { ReactElement } from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

import { currencyWithCents } from '~/lib/format';
import { Campaign } from '~/types/campaign';

export function CampaignLineItem({
  isApplied,
  activeAutoCampaign,
  price,
}: Readonly<{
  isApplied: boolean;
  activeAutoCampaign?: Campaign;
  price?: number;
}>): ReactElement {
  const cartBadge = activeAutoCampaign?.campaign_config.cart_discount_badge;
  const campaignName = activeAutoCampaign?.name;

  const itemBadge = cartBadge ? (
    <Image
      src={cartBadge}
      alt="Campaign Discount"
      width={24}
      height={24}
      className="mr-1"
      data-testid="custom-badge"
    />
  ) : (
    <FontAwesomeIcon
      icon={faTag}
      className="mr-1 text-dollar"
      data-testid="default-badge"
    />
  );

  return (
    <div className="flex">
      <div>
        {itemBadge}
        <span className="text-sm" data-testid="discount-amount-text">
          {isApplied
            ? `${activeAutoCampaign?.discount_display} off for ${campaignName}`
            : `Eligible for ${activeAutoCampaign?.discount_display} off`}
        </span>
      </div>
      {!!price && isApplied && (
        <div
          className="ml-auto text-sm text-grace line-through"
          data-testid="strikethrough-price"
        >
          {currencyWithCents(price)}
        </div>
      )}
    </div>
  );
}
