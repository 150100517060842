import { memo } from 'react';
import Image from 'next/image';

import { Link } from '~/components/global/Link';
import { getPlusDiscount } from '~/lib/util/helpers';
import { SessionObjectProps } from '~/types/user';

import PlusLogo from '~/public/as-plus.svg';

/**
 * Default nav pill/button displayed for the "Authenticated Users (without Plus)"
 * user segment if there isn't a nav pill configured in admin for the current active
 * campaing config for that user segment.
 */
export default memo(function PlusDiscountPill({
  session,
}: {
  session?: SessionObjectProps;
}) {
  return (
    <Link
      href="/plus/?ref=Plusnav"
      className="flex h-10 items-center justify-center rounded-[40px] bg-ready-red/[.15] px-5 text-midnight md:h-8"
      data-testid="plus-discount-pill"
    >
      <Image src={PlusLogo} alt="Save 10%" className="mr-[5px] align-middle" />
      <span className="whitespace-nowrap font-medium">
        Save {getPlusDiscount(session)}%
      </span>
    </Link>
  );
});
