import { getCSRFCookie } from '../util';

export class StatusError extends Error {
  response: any;
}

function checkStatus(response: any) {
  if (response.ok) {
    return response;
  } else {
    const error = new StatusError(response.statusText);
    error.response = response;
    throw error;
  }
}

export function fetchJson(url: string, args: any = {}) {
  return fetchData(url, args)
    .then((res) => res.text())
    .then((data) => (data ? JSON.parse(data) : {}));
}

export function fetchData(url: string, args: any = {}): Promise<Response> {
  const csrf = getCSRFCookie();
  const isSSR = typeof window === 'undefined';
  let baseURL =
    (isSSR
      ? process.env.DJANGO_INTERNAL_URL
      : process.env.NEXT_PUBLIC_DJANGO_PUBLIC_URL) ?? '';

  if (url.startsWith('http')) {
    baseURL = '';
  }

  const headers = args.headers || {};
  if (csrf && !headers['X-CSRFToken']) {
    headers['X-CSRFToken'] = csrf;
  }
  return fetch(`${baseURL}${url}`, {
    credentials: 'include',
    ...args,
    headers,
  })
    .then(checkStatus)
    .then((res) => res);
}
