import { ReactElement } from 'react';

import AccountLinks from '~/components/account/Global/AccountLinks';
import { ACCOUNT_PAGES } from '~/constants/accounts';

export default function AccountSidebar({
  children,
  accountPage,
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  accountPage?: string;
}>): ReactElement {
  const isProductsPage = accountPage === ACCOUNT_PAGES.PRODUCTS;

  return (
    <div className="mx-auto mb-8 mt-6 grid max-w-screen-4xl gap-6 md:mt-10 md:grid-cols-[184px,minmax(0,1fr)] md:gap-8 md:pl-8 lg:grid-cols-[216px,minmax(0,1fr)]">
      <div className="px-4 sm:px-8 md:!px-0">
        <AccountLinks accountPage={accountPage} />
      </div>
      <div
        className={`max-w-[1000px] px-4 sm:px-8 md:border-l md:border-sundog ${
          isProductsPage ? 'overflow-visible' : 'overflow-auto'
        }`}
      >
        {children}
      </div>
    </div>
  );
}
