import { useContext, useEffect, ReactElement } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Transition } from '@headlessui/react';
import { Button, NumberStepper } from '@appsumo/dorado-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faTrashCan,
  faCircleCheck,
  faCartShopping,
} from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';

import { Link } from '~/components/global/Link';
import { CampaignIncentiveVersion } from '~/lib/campaigns/constants';
import { useCart } from '~/lib/cart';
import { currency } from '~/lib/format';
import { useLayout } from '~/layouts';
import { useSavedCart } from '~/lib/cart/useCart';
import { getAvailableSavedDeals } from '~/lib/cart/util';
import { CouponType } from '~/constants/cart';
import { CartAlsoBought } from '~/components/cart/shelf/CartAlsoBought';
import { CartSkuCard } from '~/components/cart/shelf/CartSkuCard';
import { CartItemProps, SavedDeal } from '~/types/cart';
import { Campaign } from '~/types/campaign';
import { CampaignIncentive } from '~/components/checkout/Summary/CampaignIncentive';
import { CampaignLineItem } from '~/components/checkout/Summary/CampaignLineItem';
import { GlobalContext } from '~/contexts/global';

function SavedForLater() {
  const { items, moveToCart } = useSavedCart();
  const savedDeals = getAvailableSavedDeals(items);

  return (
    <>
      {!!savedDeals.length && (
        <div className="mx-4 border-t border-t-sundog py-4">
          <strong>Saved for later:</strong>
          <div className="mt-5 grid grid-cols-2 gap-4">
            {savedDeals.map((deal: SavedDeal) => (
              <CartSkuCard deal={deal} key={deal.id} onClick={moveToCart} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function CartSummary({ cart }: { cart: any }) {
  return (
    <div className="flex w-full flex-col items-start gap-y-4 rounded-lg bg-iceberg p-4 text-sm">
      <div className="flex w-full">
        <div className="grow">Subtotal</div>
        <div className="font-medium">{currency(cart.subtotal)}</div>
      </div>
      <div className="flex w-full">
        <div className="grow">Sales Tax</div>
        <div>Pending</div>
      </div>
      {cart.coupon?.coupon_id && (
        <div className="flex w-full">
          <div className="grow italic text-grace">{cart.coupon_desc}</div>
          <div className="italic text-grace">
            {`-${currency(cart.coupon_discount)}`}
          </div>
        </div>
      )}
      {cart.use_credit && Math.abs(cart.credit) !== 0 && (
        <div className="flex w-full italic">
          <div className="grow italic">
            Applied Credits ({currency(cart.credit)})
          </div>
          <div>-{currency(cart.credit)}</div>
        </div>
      )}
      <div className="flex w-full font-medium">
        <div className="grow">Total</div>
        <div className="">{currency(cart.total)}</div>
      </div>
    </div>
  );
}

function CartLineItem({
  item,
  activeAutoCampaign,
}: Readonly<{
  item: CartItemProps;
  activeAutoCampaign?: Campaign;
}>): ReactElement {
  const { lineItemUpdate, lineItemUpdatePlan, lineItemRemove } = useCart();
  const { saveDeal } = useSavedCart();
  const { deal, deal_plan } = item;
  const { cart } = useCart();
  const showDiscount =
    cart?.active_auto_campaign_coupon_applied && !!item.coupon_discount;
  const nonAutoCampaignCouponApplied =
    !!cart?.coupon?.coupon_id && !cart?.active_auto_campaign_coupon_applied;

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-2">
        <Image
          src={deal.image}
          unoptimized
          width={48}
          height={30}
          alt="Product"
          className="aspect-sku-card h-[30px] w-12 rounded"
        />
        <strong className="grow truncate font-semibold">
          {deal.public_name}
        </strong>
        <div
          className={`font-medium ${clsx({
            'text-money-moss':
              showDiscount && cart?.active_auto_campaign_coupon_applied,
          })}`}
        >
          {item.price === 0
            ? 'FREE'
            : currency(
                showDiscount
                  ? item.subtotal - item.coupon_discount
                  : item.subtotal,
              )}
        </div>
      </div>
      {!nonAutoCampaignCouponApplied && !!item.subtotal && showDiscount && (
        <CampaignLineItem
          isApplied={!!cart?.active_auto_campaign_coupon_applied}
          activeAutoCampaign={activeAutoCampaign}
          price={item.subtotal}
        />
      )}
      {deal.use_licensing ? (
        <select
          className="rounded border border-grace px-4 py-2 outline-none"
          onChange={(e) =>
            lineItemUpdatePlan(deal.id, parseInt(e.target.value, 10))
          }
          value={deal_plan.id}
        >
          {deal.plans.map((plan: any) => (
            <option key={plan.id} value={plan.id}>
              {plan.public_name}:{' '}
              {plan.price === 0 ? 'FREE' : currency(plan.price)}
            </option>
          ))}
        </select>
      ) : (
        <div className="rounded border border-grace px-4 py-2">
          {deal_plan?.plan_category}
        </div>
      )}
      <div className="flex items-center gap-x-2">
        <NumberStepper
          min={1}
          max={deal.user_quantity_limit}
          value={item.quantity}
          onChange={(value) => lineItemUpdate(deal.id, value)}
        />
        <div className="flex grow items-end justify-end gap-x-4">
          <button
            className="inline-flex items-center gap-x-2 text-sm text-brick"
            onClick={() => lineItemRemove(deal.id)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
            Remove
          </button>
          <button
            className="inline-flex items-center gap-x-2 text-sm text-bolt"
            onClick={() => saveDeal(deal.id)}
          >
            <FontAwesomeIcon icon={faBookmark} />
            Save for later
          </button>
        </div>
      </div>
    </div>
  );
}

function CartBody({ onClose }: Readonly<{ onClose: () => void }>) {
  const { cart } = useCart();
  const { cart_items: items } = cart || {};
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);

  return (
    <>
      <div
        className={`flex grow flex-col gap-y-4 p-4 ${
          items?.length === 0 ? 'justify-center' : ''
        }`}
      >
        {items?.map((item: CartItemProps) => (
          <CartLineItem
            key={item.deal.id}
            item={item}
            activeAutoCampaign={activeAutoCampaign}
          />
        ))}
        {items?.length === 0 && (
          <div className="text-center text-sm">
            <p>
              Well, this is awkward. You don&apos;t have any items in your cart
              right now.
            </p>
            <Link href="/software/" className="text-blue-600 underline">
              Browse current software deals
            </Link>
          </div>
        )}
        {items?.length > 0 && (
          <>
            <CartSummary cart={cart} />
            <div className="flex flex-col gap-y-4">
              <Button href="/cart/" className="text-center">
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="mr-2 text-sm"
                />
                Go to cart
              </Button>
              <Button tertiary onClick={onClose}>
                Continue shopping
              </Button>
            </div>
          </>
        )}
      </div>
      <SavedForLater />
      {items && items?.length > 0 && (
        <CartAlsoBought dealIds={items.map((item: any) => item.deal.id)} />
      )}
    </>
  );
}

function CartCoupon() {
  const { cart } = useCart();
  const {
    campaigns: { showCampaignIncentive },
  } = useContext(GlobalContext);

  return (
    <>
      {!!cart?.coupon?.coupon_id && !!cart.coupon_discount && (
        <div className="bg-lime/30 px-4 py-2 text-center text-sm font-bold">
          <FontAwesomeIcon icon={faCircleCheck} className="mr-1 text-dollar" />
          {cart.coupon.type === CouponType.PERCENTAGE_OFF
            ? `Congrats! You're getting ${cart.coupon.value}% off!`
            : `Congrats! ${cart.coupon_desc}!`}
        </div>
      )}

      {showCampaignIncentive && (
        <CampaignIncentive version={CampaignIncentiveVersion.CART_SHELF} />
      )}
    </>
  );
}

export function CartShelf({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { setCanScrollBody } = useLayout();

  useEffect(() => {
    setCanScrollBody(open !== true);
  }, [open, setCanScrollBody]);

  return (
    <Transition show={open}>
      <Transition.Child
        enter="transition-[right] duration-300 ease-in-out"
        enterFrom="-right-[350px]"
        enterTo="right-0"
        leave="transition-[right] duration-300 ease-in-out"
        leaveFrom="right-0"
        leaveTo="-right-[350px]"
        className={`fixed inset-y-0 z-50 flex h-screen w-[350px] flex-col overflow-y-scroll bg-white text-black-pearl shadow max-sm:left-10 max-sm:w-full max-sm:pr-10`}
      >
        <CartCoupon />
        <div className="sticky top-0 shrink bg-white p-4">
          <h5 className="font-header text-2xl font-bold">Cart</h5>
          <button className="absolute top-5 right-5" onClick={onClose}>
            <span className="sr-only">Close Cart</span>
            <FontAwesomeIcon icon={faTimes} className="text-xl" />
          </button>
        </div>
        <CartBody onClose={onClose} />
      </Transition.Child>
      <Transition.Child
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        leave="transition-opacity duration-75"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
        className="fixed inset-0 h-screen w-screen bg-black"
        onClick={onClose}
      ></Transition.Child>
    </Transition>
  );
}
