const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});
const thousandsFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' });

export function currency(number: number): string {
  const formattedCurrency = currencyFormatter.format(number);
  return formattedCurrency.endsWith('.00')
    ? formattedCurrency.slice(0, -3)
    : formattedCurrency;
}

export function currencyWithCents(number?: number): string {
  return currencyFormatter.format(number || 0);
}

export function thousands(number: number): string {
  return thousandsFormatter.format(number);
}

// generates "Website templates" from "Websites" & "Templates"
export function taxonomyTitle(group: string, category?: string): string {
  const lowerGroup = group.toLowerCase();

  if (category) {
    const words = `${category}`.split(' ').filter(Boolean);

    if (words.length > 1) {
      const lastWord = words[words.length - 1].toLowerCase().replace(/s+$/, '');
      const trimmedGroup = lowerGroup.replace(/s+$/, '');

      if (lastWord === trimmedGroup) {
        // remove duplicate trailing duplicate word
        words.pop();
      }
    }

    if (!(words[words.length - 1] === 'themes' && lowerGroup === 'templates')) {
      words[words.length - 1].replace(/s+$/, '');
      words.push(lowerGroup);
    }

    return words.join(' ');
  }

  return group;
}
