import { memo, useContext, useMemo, useState } from 'react';
import Image from 'next/image';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useInterval } from 'usehooks-ts';

import { Link } from '~/components/global/Link';
import { GlobalContext } from '~/contexts/global';
import useUser from '~/lib/user';
import { BaseNavPill } from './NavPills';

import ShopExclusives from '../../../public/shop-exclusives.svg';

dayjs.extend(relativeTime);

interface CampaignTimerButtonProps {
  label: string;
  href: string;
  endDate: string;
}

function CampaignTimerButton({
  label,
  href,
  endDate,
}: CampaignTimerButtonProps) {
  const [now, setNow] = useState<Dayjs>(dayjs());

  useInterval(() => {
    setNow(dayjs());
  }, 1000);

  const timeRemaining = useMemo(() => {
    const end = dayjs(endDate);
    let seconds = end.diff(now, 'second');
    if (seconds > 0) {
      const hours = Math.floor(seconds / 3600);
      const mins = Math.floor(seconds / 60) % 60;
      seconds %= 60;
      const parts = [];
      if (hours) {
        parts.push(String(hours).padStart(2, '0'));
      }
      parts.push(String(mins).padStart(2, '0'));
      if (hours || mins || seconds) {
        parts.push(String(seconds).padStart(2, '0'));
      }
      return parts.join(':');
    }

    return '00:00';
  }, [endDate, now]);

  return (
    <Link
      href={`${href}`}
      className="flex items-center justify-center rounded-[40px] bg-bolt/20 px-5 py-1"
    >
      <Image
        src={ShopExclusives}
        alt={label}
        className="mr-[5px] align-middle"
      />
      <strong className="">{label}</strong>
      <span className="ml-1.5">{timeRemaining}</span>
    </Link>
  );
}

export default memo(function NavPromo() {
  const {
    campaigns: { activeAutoCampaign, isLoading: isLoadingCampaignConfigs },
  } = useContext(GlobalContext);
  const { session, user, isLoading: isLoadingUser } = useUser();
  const { button } = session || {};

  const isCampaign = useMemo(() => {
    if (button?.end_date) {
      const now = dayjs();
      const ends = dayjs(button.end_date);
      return now < ends;
    }

    return false;
  }, [button?.end_date]);

  const hasUser = useMemo(() => user && user.is_authenticated, [user]);
  const hasPlus = useMemo(() => user && user.has_plus, [user]);
  const isLoading = useMemo(
    () => isLoadingUser || isLoadingCampaignConfigs,
    [isLoadingCampaignConfigs, isLoadingUser],
  );

  if (isLoading) {
    return <div />;
  }

  // Default to base nav pill.
  let buttonEl = (
    <BaseNavPill
      isAuthenticated={hasUser}
      hasPlus={hasPlus}
      activeAutoCampaign={activeAutoCampaign}
      session={session}
    />
  );

  // Campaign button overrides all other nav pills.
  if (isCampaign && button) {
    buttonEl = (
      <CampaignTimerButton
        label={button.label}
        href={button.link}
        endDate={button.end_date}
      />
    );
  }

  // As long the AskSumo navItem is present we need to hide the pill between 768px and 1024px
  // to avoid overlapping with the nav items, using the class `md:max-lg:hidden`
  // see components/navigation/components/NavGroups.tsx for AskSumo nav item.
  return (
    <div className="my-6 w-full md:my-0 md:w-auto md:max-lg:hidden">
      {buttonEl}
    </div>
  );
});
