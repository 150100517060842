import { useRef, useState } from 'react';
import { ACTIVITY_TYPE } from '~/lib/activity/constants';

import { fetchData } from '~/lib/fetch';
import { ActivityProps, UseActivityProps } from '~/types/activity';

export function useActivity(): UseActivityProps {
  const [activityLogs, setActivityLogs] = useState<Array<ActivityProps>>([]);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const activityLogsRef = useRef<Array<ActivityProps>>(activityLogs);
  const intervalRef = useRef<NodeJS.Timeout | null>(intervalId);

  const resetActivities = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setIntervalId(null);
    setActivityLogs([]);

    intervalRef.current = null;
    activityLogsRef.current = [];
  };

  const addActivity = (activity: ActivityProps) => {
    // if no interval, create one
    if (!intervalRef.current) {
      const interval = setInterval(processActivities, 1000);
      setIntervalId(interval);
      intervalRef.current = interval;
    }

    setActivityLogs((prev) => {
      // set time if none was given
      if (!activity.time) activity.time = new Date().toISOString();
      activityLogsRef.current = [...prev, activity];
      return activityLogsRef.current;
    });
  };

  const processActivities = async () => {
    if (
      !activityLogsRef.current?.length ||
      !Array.isArray(activityLogsRef.current)
    ) {
      resetActivities();
      return;
    }

    try {
      if (!activityLogsRef.current?.length) return;

      await fetchData('/api/activity/batch/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(activityLogsRef.current),
      });
    } catch (err) {
      console.error('There was an error logging the activity', err);
    } finally {
      resetActivities();
    }
  };

  return {
    addActivity,
    resetActivities,
    processActivities,
  };
}

export const addDealViewActivity = async ({
  dealId,
}: {
  dealId: number;
}): Promise<void> => {
  await fetchData(`/deal/${dealId}/activity/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      activity_event: ACTIVITY_TYPE.DEAL_VIEW,
    }),
  });
};
