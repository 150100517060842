import { createContext } from 'react';
import { CampaignConfigsHookProps } from '~/types/campaign';
import { DealImpressionProps } from '~/types/deal';
import { UserStateProps } from '~/types/user';

export interface GlobalContextProps {
  campaigns: CampaignConfigsHookProps;
  dealImpressions: DealImpressionProps;
  experimentVariants: {
    [key: string]: string;
  };
  hasUserDataOnDatalayer: boolean;
  userState: UserStateProps;
  enrolledExperiments: {
    [key: string]: string;
  };
}

export const GlobalContext = createContext<GlobalContextProps>({
  campaigns: <CampaignConfigsHookProps>{
    activeAutoCampaign: undefined,
    allowAsksumoDiscount: false,
    campaignConfigs: {},
    showCampaignIncentive: false,
  },
  dealImpressions: <DealImpressionProps>{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addDealImpression: (dealId: number) => {},
    resetImpressions: () => {},
  },
  experimentVariants: {},
  hasUserDataOnDatalayer: false,
  userState: {
    is_authenticated: false,
    has_briefcase: false,
    has_partner_portal: false,
    has_plus: false,
    is_betaling: false,
    is_staff: false,
  },
  enrolledExperiments: {},
});
