import { useContext, useEffect, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faTag } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Image from 'next/image';
import { useLockedBody, useWindowSize } from 'usehooks-ts';

import { GlobalContext } from '~/contexts/global';
import useUser from '~/lib/user';
import { NavItemProps } from '~/types/layout';

import { Link } from '~/components/global/Link';
import logoDark from '../../public/as-appsumo-logo-dark.svg';
import NavControls from './components/NavControls';
import NavGroups from './components/NavGroups';
import NavPromo from './components/NavPromo';
import NavUser from './components/NavUser';
import SearchInput from './components/SearchInput';
import SellOnAppsumo from './components/SellOnAppsumo';
import PartnerPortal from './components/PartnerPortal';

export const DEFAULT_NAV_ITEMS = [
  { name: 'Software', href: '/software/' },
  { name: 'Courses & more', href: '/courses-more/' },
  { name: 'New arrivals', href: '/collections/new/' },
  { name: 'Ending soon', href: '/collections/ending-soon/' },
];

export default function NavHeader({
  isCollectionBrowsePage,
  isCartPage,
}: Readonly<{
  isCollectionBrowsePage?: boolean;
  isCartPage?: boolean;
}>) {
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  const router = useRouter();
  const { user } = useUser();
  const [showMobileMenu, setShowMobileMenu] = useLockedBody(false, 'root');
  const handleMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const { height } = useWindowSize();
  const [vh, setVh] = useState<string>(`${height || 0}px`);

  useEffect(() => setVh(`${height}px`), [height]);

  useEffect(() => {
    const handleRouteChange = () => {
      setShowMobileMenu(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, setShowMobileMenu]);

  // Add Campaign Nav Item
  const showCampaignNavItem: boolean =
    !!activeAutoCampaign?.campaign_config?.show_navbar_item;

  const campaignNavItem: NavItemProps[] =
    showCampaignNavItem && activeAutoCampaign?.campaign_config
      ? [
          {
            name:
              activeAutoCampaign.campaign_config?.navbar_item_text ||
              'Campaign',
            href: `/collections/${activeAutoCampaign.campaign_config?.smart_collection_slug}/`,
            icon: {
              src: faTag,
              className: 'mr-2',
              style: {
                color:
                  activeAutoCampaign.campaign_config?.navbar_item_font_color,
              } as React.CSSProperties,
            },
          },
        ]
      : [];

  const navItems = DEFAULT_NAV_ITEMS;
  const navGroupUrlList = useMemo(() => {
    return navItems.map((item) => item.href.split('?')[0]);
  }, [navItems]);

  // navGroupsPromoComponent and userButton do not show on cart page
  const navGroupsPromoComponent = !isCartPage ? (
    <div
      className={clsx(
        { hidden: !showMobileMenu },
        'order-4 h-auto',
        'col-[full] flex flex-col items-center justify-center md:flex md:h-[54px] md:flex-row',
        { block: showMobileMenu },
      )}
    >
      <NavGroups navItems={[...navItems, ...campaignNavItem]} />
      <NavPromo />
    </div>
  ) : null;

  let userButton = <SellOnAppsumo />;
  let searchInputComponent = (
    <SearchInput
      isCollectionBrowsePage={isCollectionBrowsePage}
      navGroupUrlList={navGroupUrlList}
    />
  );

  if (isCartPage) {
    userButton = <></>;
    searchInputComponent = <></>;
  } else if (user?.has_partner_portal) {
    userButton = <PartnerPortal />;
  }

  return (
    <div
      id="header"
      className={clsx(
        'fixed left-0 top-0 z-10 w-full',
        'before:fixed before:left-0 before:top-0 before:-z-1 before:h-screen before:w-screen before:bg-black',
        'before:transition-opacity before:duration-300 before:ease-in-out before:content-[""]',
        showMobileMenu
          ? 'before:visible before:opacity-30 '
          : 'before:invisible before:opacity-0',
      )}
    >
      <div
        style={
          {
            '--vh': `${vh}`,
          } as React.CSSProperties
        }
        className={clsx(
          'z-20 w-full border-b border-navbar bg-navbar text-navbar',
          'mx-auto max-w-screen-4xl px-4 pt-[10px] md:px-8 md:pt-[18px]',
          showMobileMenu ? 'max-h-[var(--vh)] overflow-y-scroll' : '',
          isCartPage ? 'pb-[10px] md:pb-[18px]' : '',
        )}
      >
        <nav
          className={clsx(
            'grid grid-cols-[[full-start]_20%_1fr_20%_[full-end]] items-center md:mt-0 md:gap-y-[10px]',
            'md:grid-cols-[[full-start]_min-content_1fr_min-content_min-content_[full-end]]',
          )}
        >
          <div className="flex md:hidden">
            <button onClick={handleMenuToggle}>
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon
                icon={showMobileMenu ? faTimes : faBars}
                width={20}
                height={20}
              />
            </button>
          </div>
          <div data-testid="header-logo">
            <Link
              href="/"
              className="mx-auto flex h-[48px] w-[100px] items-center md:w-[150px]"
            >
              <span className="sr-only">AppSumo</span>
              <Image alt="" src={logoDark} width="150" height="23" priority />
            </Link>
          </div>
          {searchInputComponent}
          <div
            className={clsx(
              'order-5 col-[full] mx-[-30px] mt-[-15px] items-center justify-end bg-gray-100 px-[30px] py-[15px] max-md:shadow-inner',
              'md:order-none md:col-auto md:m-0 md:flex md:bg-transparent md:p-0',
              { block: showMobileMenu },
              { hidden: !showMobileMenu },
            )}
          >
            {userButton}
            <NavUser />
          </div>
          <NavControls />
          {navGroupsPromoComponent}
        </nav>
      </div>
    </div>
  );
}
