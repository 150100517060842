import { ParsedUrlQuery } from 'querystring';

export function _createFormData(
  query: ParsedUrlQuery,
  customData?: Object,
): FormData {
  const { utm_source, utm_campaign, utm_medium } = query;
  const formData = new FormData();

  if (utm_source) {
    formData.append('source', utm_source as string);
  }

  if (utm_campaign) {
    formData.append('campaign', utm_campaign as string);
  }

  if (utm_medium) {
    formData.append('medium', utm_medium as string);
  }

  if (customData && typeof customData === 'object') {
    Object.entries(customData).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }

  return formData;
}

export function fetchEmailSubscribe(
  url: string,
  data: FormData,
  token: string,
): Promise<Response> {
  const headers = new Headers();
  headers.append('X-CSRFToken', token);

  const baseUrl =
    (typeof window === 'undefined'
      ? process.env.DJANGO_INTERNAL_URL
      : process.env.NEXT_PUBLIC_DJANGO_PUBLIC_URL) || '';

  return fetch(baseUrl + url, {
    credentials: 'include',
    method: 'POST',
    headers,
    body: data,
  });
}
