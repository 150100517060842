import React, { forwardRef, MouseEvent, ReactNode, Ref } from 'react';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import {
  isPDP,
  PDP_REGEX,
  isIndexablePage,
  IS_INDEXABLE_PAGE_REGEX,
} from '~/lib/util/constants';
import { ACCOUNT_PAGES } from '~/constants/accounts';

const URL_WHITELIST_EXACT: Array<string> = [
  '/',
  '/account/',
  ...Object.keys(ACCOUNT_PAGES).map(
    (page) => `/account/${ACCOUNT_PAGES[page as keyof typeof ACCOUNT_PAGES]}/`,
  ),
];

const URL_WHITELIST_PREFIX: Array<string> = [
  '/browse',
  '/search',
  '/software',
  '/creative-resources',
  '/templates',
  '/courses-learning',
  '/collections',
  '/asksumo/',
  '/courses-more',
  '/cart/',
  '/checkout/',
];
const URL_WHITELIST_CONTAINS: Array<string> = ['#reviews'];

export const Link = forwardRef(function Link(
  {
    children,
    href,
    callback,
    target = '_self',
    ...props
  }: {
    children?: ReactNode;
    href: string;
    callback?: () => void;
    target?: '_self' | '_blank';
  } & (React.HTMLAttributes<HTMLAnchorElement> | LinkProps),
  ref: Ref<HTMLAnchorElement>,
) {
  const router = useRouter();

  const whitelistPrefix: Array<string> = [...URL_WHITELIST_PREFIX];
  const whitelistExact: Array<string> = [...URL_WHITELIST_EXACT];
  const whitelistRegex: Array<RegExp> = [];
  const whitelistContains: Array<string> = [...URL_WHITELIST_CONTAINS];

  const customLinkOnClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (callback) {
      callback();
    }

    // Using props.onClick to use the actual prop passed in
    // to this component and not the built-in one in `HTMLAnchorElement`.
    if (props.onClick) {
      props.onClick(e as MouseEvent<HTMLAnchorElement>);
    }

    // if user is holding down cmd or windows key, open in new tab
    if (e.metaKey) {
      window.open(href, '_blank');
      return;
    }
    router.push(href);
  };

  if (!href) {
    return <></>;
  }

  if (href.includes('/accounts/logout')) {
    return (
      <a ref={ref} href={href} target={target} {...props}>
        {children}
      </a>
    );
  }

  if (isPDP(href)) {
    whitelistRegex.push(PDP_REGEX);
  }

  if (isIndexablePage(href)) {
    whitelistRegex.push(IS_INDEXABLE_PAGE_REGEX);
  }

  // if link is whitelisted use next/link to render the a tag
  if (
    (target === '_self' &&
      (whitelistExact.some((url) => href === url) ||
        whitelistPrefix.some((url) => href.startsWith(url)) ||
        whitelistRegex.some((regex) => regex.test(href)))) ||
    whitelistContains.some((url) => href.includes(url))
  ) {
    return (
      <a href={href} target={target} {...props} onClick={customLinkOnClick}>
        {children}
      </a>
    );
  }

  return (
    <a ref={ref} href={href} target={target} {...props}>
      {children}
    </a>
  );
});
