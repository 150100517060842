import { memo } from 'react';
import Image from 'next/image';

import { Link } from '~/components/global/Link';
import ShopExclusives from '~/public/shop-exclusives.svg';

/**
 * Default nav pill/button displayed for the "Plus Users" user segment
 * if there isn't a nav pill configured in admin for the current active
 * campaing config for that user segment.
 */
export default memo(function ShopPlusExclusivesPill() {
  return (
    <Link
      href="/collections/plus-exclusives/?ref=PEnav"
      className="flex h-10 items-center justify-center whitespace-nowrap rounded-[40px] bg-bolt/20 px-5 text-midnight md:h-8"
      data-testid="shop-plus-exclusives-pill"
    >
      <Image
        src={ShopExclusives}
        alt="Shop Plus exclusives"
        className="mr-[5px] h-6 align-middle"
      />
      <strong>Shop Plus exclusives</strong>
    </Link>
  );
});
