import { ReactElement } from 'react';
import { Heading } from '@appsumo/dorado-react';

import Image, { StaticImageData } from 'next/image';
import shopify from '~/public/shopify.svg';
import zapier from '~/public/zapier.svg';
import intercom from '~/public/intercom.svg';
import twilio from '~/public/twilio.svg';
import depositphotos from '~/public/depositphotos.svg';
import mailchimp from '~/public/mailchimp.svg';

type Logo = {
  src: StaticImageData;
  alt: string;
  parentClass: string;
};

const logos: Logo[] = [
  {
    src: shopify,
    alt: 'Shopify logo',
    parentClass: 'col-span-1 flex justify-center',
  },
  {
    src: zapier,
    alt: 'Zapier logo',
    parentClass: 'col-span-1 flex justify-center',
  },
  {
    src: intercom,
    alt: 'Intercom logo',
    parentClass: 'hidden md:block col-span-1',
  },
  {
    src: twilio,
    alt: 'Twilio logo',
    parentClass: 'col-span-1 flex justify-center',
  },
  {
    src: depositphotos,
    alt: 'Depositphotos logo',
    parentClass: 'col-span-1 flex justify-center',
  },
  {
    src: mailchimp,
    alt: 'Mailchimp logo',
    parentClass: 'hidden md:block col-span-1',
  },
];

export default function SocialProofFooter(): ReactElement {
  return (
    <div className="bg-black-pearl pt-6 md:pt-[48px]">
      <Heading.H4 className="text-center text-xl text-white">
        Heard of these powerhouses?&nbsp;
        <br className="block md:hidden" />
        They started right here.
      </Heading.H4>

      <div className="mt-6 mb-8 grid grid-cols-2 items-center justify-center gap-8 px-[10%] md:mt-8 md:mb-[48px] md:grid-cols-6">
        {logos.map((logo, index) => (
          <div key={index} className={logo.parentClass}>
            <Image src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>

      <hr className="mx-auto w-[94%] border-grace" />
    </div>
  );
}
