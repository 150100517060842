import { useEffect, useState } from 'react';

import useUser from './user';
import { getGA4UserType, gtag } from './util/dataLayer';
import { User } from '~/types/gtag';

export default function useUserDataLayer() {
  const { user } = useUser();

  /**
   * Check if user object is a `fallback` one. For some reason in the beginning the `user`
   * has a few default parameters. However, the user object changes to `null` for anonymous users.
   *
   * fallback user:
   * {
   *   has_briefcase: false,
   *   has_partner_portal: false,
   *   has_plus: false,
   *   is_authenticated: false
   *   is_betaling: false
   *   is_staff: false
   * }
   *
   * anonymous user: null
   */
  const isFallbackUser = user !== null && !user?.id;
  const isAnonymous = user === null;

  // Control if the user data was already set on the datalayer.
  const [hasUserDataOnDatalayer, setHasUserDataOnDatalayer] = useState(false);

  useEffect(() => {
    if (isFallbackUser || hasUserDataOnDatalayer) {
      return;
    }

    // Anonymous users needs to be tracked as well.
    if (isAnonymous) {
      setHasUserDataOnDatalayer(true);
      return;
    }

    const userData: User = {
      userId: user.id,
      userEmail: user.email,
      userEmailHash: user.email_hash,
      hasPlus: user.has_plus,
      hasBriefcase: user.has_briefcase,
      isStaff: user.is_staff,
      isBetaling: user.is_betaling,
    };

    gtag('set', userData);
    // Custom event to know when the user data was set and trigger tags that depends
    // on user data. i.e: The klaviyo identify tag.
    gtag('event', 'set_user_data');
    gtag('set', 'user_properties', {
      // this just gets the user type based on is_staff, is_betaling, has_plus which are already dependencies
      user_type: getGA4UserType(user),
    });
    setHasUserDataOnDatalayer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.id,
    user?.email,
    user?.email_hash,
    user?.has_plus,
    user?.has_briefcase,
    user?.is_staff,
    user?.is_betaling,
  ]);

  return hasUserDataOnDatalayer;
}
