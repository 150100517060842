import { GetStaticPropsResult, Redirect } from 'next';
import { ParsedUrlQuery, encode } from 'querystring';

import {
  CommentUser,
  DISCUSSION_STATUS_UNMODERATED,
  DiscussionComment,
  DiscussionQuestion,
  DiscussionReview,
  DiscussionStatus,
  DiscussionType,
  IDiscussionUserInfoProps,
  IQuestionCommentProps,
  QuestionV2,
} from '~/lib/discussions/types';
import { fetchJson } from '~/lib/fetch';
import { CommentType, CommentV2Type } from '~/static/discussions/enums';
import { UserObjectProps } from '~/types/user';
import {
  QUESTION_HEADER_MAX_LENGTH,
  QUESTION_HEADER_MAX_LENGTH_SCALAR,
} from '~/constants/discussions';

interface Headers {
  [key: string]: string;
}

export function getCSRF(): string | null {
  const csrfPrefix = document.cookie?.split('csrftoken=');

  if (csrfPrefix?.length !== 2) {
    return null;
  }

  return csrfPrefix[1].split(';')[0];
}

export function getCommentV2(
  dealId: number,
  type: CommentV2Type,
  discussionId: number,
  urlPathSuffix: string = '',
  headers: Headers = {},
): Promise<DiscussionComment> {
  return fetchJson(
    `/api/v2/deals/${dealId}/${type}s/${discussionId}/?url_path_suffix=${urlPathSuffix}`,
    {
      headers,
    },
  );
}

export function getQuestionV2(
  dealId: number,
  discussionId: number,
  urlPathSuffix: string = '',
  headers: Headers = {},
): Promise<DiscussionQuestion> {
  return getCommentV2(
    dealId,
    CommentV2Type.Question,
    discussionId,
    urlPathSuffix,
    headers,
  ) as Promise<DiscussionQuestion>;
}

export function getReviewV2(
  dealId: number,
  discussionId: number,
  urlPathSuffix: string = '',
  headers: Headers = {},
): Promise<DiscussionReview> {
  return getCommentV2(
    dealId,
    CommentV2Type.Review,
    discussionId,
    urlPathSuffix,
    headers,
  ) as Promise<DiscussionReview>;
}

export function getComment(
  dealId: number,
  group: CommentType,
  discussionId: number,
  headers: Headers = {},
): Promise<DiscussionComment> {
  return fetchJson(
    `/discussions/api/listcomments/${dealId}/?group=${group}&thread_id=${discussionId}&for_discussion_page=True`,
    {
      headers,
    },
  );
}

export function redirectToHome(): GetStaticPropsResult<Redirect> {
  return {
    redirect: {
      statusCode: 302,
      destination: '/',
    },
  };
}

export function redirectToPdp(
  slug: string,
  hash?: string,
  query?: ParsedUrlQuery,
): GetStaticPropsResult<Redirect> {
  let queryString = '';

  if (query) {
    const queryParams = new URLSearchParams(encode(query));
    queryParams.delete('slug'); // query take url slug as query param

    queryString = `?${queryParams.toString()}`;
  }

  return {
    redirect: {
      statusCode: 302,
      destination:
        `/products/${slug}/` + (hash ? `#${hash}` : '') + (queryString || ''),
    },
  };
}

export const mapToQuestionComment = (
  question: QuestionV2,
  mainQuestionId: number,
  user: UserObjectProps,
  partnersIds: number[],
  isNew: boolean = false,
): IQuestionCommentProps => {
  const item = {
    ...question,
    status: question.status ?? DISCUSSION_STATUS_UNMODERATED,
  } as QuestionV2;

  if (!item.user) {
    item.user = { ...user, comment_whitelist: false } as CommentUser;
  }

  const userInfo: IDiscussionUserInfoProps = {
    id: item.user.id,
    username: item.user.username,
    avatar: item.user.avatar,
    isVerified: item.purchased,
    isPlus: item.user.has_plus ?? false,
    commentDate: new Date(item.created),
    isFounder: partnersIds.includes(item.user.id),
    dealsBought: null,
    memberSinceDate: null,
  };

  return {
    id: item.id,
    questionId: mainQuestionId,
    dealId: item.deal_id,
    text: item.comment,
    level: item.level,
    userInfo,
    status: item.status?.toLowerCase() as DiscussionStatus,
    comments: item.children?.map((child) =>
      mapToQuestionComment(child, mainQuestionId, user, partnersIds),
    ),
    isNew,
  } as IQuestionCommentProps;
};

export const getQAndRPageCanonicalUrl = (slug: string, type: DiscussionType) =>
  `/products/${slug}/${type}/`;

/**
 * Get the previous word at a given position in a string.
 * @param {string} str - Text to search
 * @param {number} pos - Position to search
 * @returns {[string, number]} Array<[word at position, start position of word]>
 */
export const getWordAt = (str: string, pos: number): [string, number] => {
  // check ranges
  if (pos < 0 || pos > str.length) {
    return ['', 0];
  }

  // Perform type conversions.
  str = String(str);
  pos = Number(pos) >>> 0;

  // Search for the preceding word
  const left = str.slice(0, pos + 1).search(/\S+\s*$/);
  const right = str.slice(pos).search(/\s/);

  // The last word in the string is a special case.
  return [str.slice(left, right < 0 ? 0 : right + pos), left];
};

/**
 * Extract the header and body from a comment.
 * @param {string} comment - Comment to extract header and body from
 * @param {Object} options - Options for extracting the header and body
 * @param {boolean} options.ellipsis - Whether to add an ellipsis to the end of the header
 * @returns {[string, string]} Array<[header text, body text]>
 */
export const getHeaderBodyFromComment = (
  comment: string,
  options: { ellipsis: boolean },
): [string, string] => {
  /**
   * Split the comment into sentences. Set the first sentence as the header and the rest as the body.
   * If the first sentence is too long or there are no "sentences" (ie. missing punctuation),
   * truncate it as the header with optional ellipsis.
   * If first sentence is a little long, then show a few extra words in the header.
   * Fallback to the full comment if unable to split into sentences
   */
  const { ellipsis } = options;
  const sentenceArr = comment.match(/\(?"?[^.?!]+[.!?]+\)?"?/g);
  const scaledMaxLength =
    QUESTION_HEADER_MAX_LENGTH * QUESTION_HEADER_MAX_LENGTH_SCALAR;
  let headerText = comment ?? '';
  let bodyText = comment.length >= scaledMaxLength ? comment : '';

  if (
    (sentenceArr?.[0].length ?? 0) >= scaledMaxLength ||
    (!sentenceArr?.[0] && comment.length >= scaledMaxLength)
  ) {
    const [, wordPos] = getWordAt(comment, QUESTION_HEADER_MAX_LENGTH);

    // JIC cannot find a word
    if (wordPos > -1) {
      headerText = comment.substring(0, wordPos) + (ellipsis ? '...' : '');
      bodyText = comment.substring(wordPos);
    }
  } else if (sentenceArr?.[0].length) {
    headerText = sentenceArr[0];
    bodyText =
      sentenceArr[0] !== comment ? comment.slice(sentenceArr[0].length) : '';
  }

  return [headerText, bodyText];
};

export const getAvatarProps = (
  avatar: string,
  username: string,
  size: number,
) => {
  return {
    src: avatar?.includes('gravatar') ? avatar : `${avatar}?width=150`,
    alt: username,
    height: size,
    width: size,
    unoptimized: true,
    loader: ({ src }: { src: string }) =>
      avatar?.includes('gravatar')
        ? src
        : `${src}?width=${size}&height=${size}`,
  };
};
