import { DEFAULT_BILLING_DETAILS } from '~/constants/checkout';

export const CART_ITEMS_URL = '/api/v2/cart/items/';

export const INITIAL_CONTEXT = {
  cart: undefined,
  user: undefined,
  savedItems: undefined,
  billingDetails: DEFAULT_BILLING_DETAILS,
  showCartItemTimer: false,
};
export const SUBSCRIPTIONS_HEADER_TITLE_LIST = ['Product', 'Price'];
export const DEALS_HEADER_TITLE_LIST = [
  'Product',
  'Plan type',
  'Quantity',
  'Price',
];

export enum CouponType {
  FIXED = 0,
  PERCENTAGE_OFF = 1,
}

export enum FrictionUserType {
  PLUS = 'plus',
  NONPLUS = 'non_plus',
  STAFF = 'staff_only',
}

export enum FRICTION_REASON_FLOWS {
  REGULAR = 'regular_feedback',
  SUPPORT = 'support_ticket',
  UPGRADE = 'upgrade',
  CHANGE_PLAN = 'licensing_change_plan',
  OTHER = 'other',
}
