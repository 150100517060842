import { memo, useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';

import useUser from '~/lib/user';
import { GlobalContext } from '~/contexts/global';
import { Link } from '~/components/global/Link';

import CommentDots from '~/public/comment-dots.svg';
import Refresh from '~/public/refresh.svg';

/**
 * Default nav pill/button displayed for the "Unauthenticated Users"
 * user segment if there isn't a nav pill configured in admin
 * for the current active campaing config for that user segment.
 */
export default memo(function AskSumoPill() {
  const router = useRouter();
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  const { user } = useUser();
  const isNewBuyer = useMemo(
    () => !(user?.number_of_purchases && user?.number_of_purchases > 0),
    [user],
  );
  const hasAskedQuestion = useMemo(
    () => router.pathname === '/asksumo' && !!router.query.question,
    [router.pathname, router.query.question],
  );

  const showAskSumoDiscount =
    activeAutoCampaign?.campaign_config?.allow_asksumo_discount;

  const renderTextContent = useCallback(() => {
    // User is in the AskSumo page AND a question has been asked there.
    if (hasAskedQuestion) {
      return (
        <>
          <strong>AskSumo</strong>{' '}
          <span className="md:max-lg:hidden">&nbsp;a new question</span>
        </>
      );
    }

    // User is new buyer AND the active auto campaign allows AskSumo discount.
    if (isNewBuyer && !showAskSumoDiscount) {
      return (
        <>
          <strong>AskSumo</strong>{' '}
          <span className="md:max-lg:hidden">&nbsp;and get 10% off</span>
        </>
      );
    }

    return (
      <>
        <span className="md:max-lg:hidden">
          Introducing&nbsp;<strong>AskSumo</strong>, try it now
        </span>
        <span className="max-md:hidden lg:hidden">
          Try <strong>AskSumo</strong>
        </span>
      </>
    );
  }, [hasAskedQuestion, isNewBuyer, showAskSumoDiscount]);

  return (
    <Link
      href="/asksumo/?ref=asksumo-navitem"
      className="flex h-10 items-center justify-center whitespace-nowrap rounded-[40px] bg-gradient-to-r from-[#BCF2CB] to-[#AEDBF4] px-5 text-midnight md:h-8"
      data-testid="asksumo-pill"
    >
      <Image
        src={hasAskedQuestion ? Refresh : CommentDots}
        alt="Ask Sumo"
        className="mr-[5px] align-middle"
      />
      {renderTextContent()}
    </Link>
  );
});
