import { ReactElement, memo, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';

import { ACCOUNT_PAGES } from '~/constants/accounts';
import { AFFILIATE_URL } from '~/constants/global';

import { Link } from '~/components/global/Link';
import { Dropdown, DropdownProps } from '@appsumo/dorado-react';

import {
  faUser,
  faFileInvoiceDollar,
  faPlus,
  faSignOut,
  faThList,
  faUsers,
  faTicket,
} from '@fortawesome/free-solid-svg-icons';

interface AccountLinkProps {
  href: string;
  label: string;
  target?: '_blank';
  icon: IconLookup;
}

enum EXTERNAL_LINKS {
  LOGOUT = 'logout',
  AFFILIATE = 'affiliate',
}

// Exclude redemption page because we don't want it in the links.
type AccountLinkTypes =
  | Exclude<ACCOUNT_PAGES, ACCOUNT_PAGES.REDEMPTION>
  | EXTERNAL_LINKS;

const ACCOUNT_DEFAULT_KEY = 'root';
const ACCOUNT_LINKS = {
  [ACCOUNT_PAGES.PROFILE]: {
    href: '/account/profile/',
    label: 'Profile',
    target: undefined,
    icon: faUser,
  },
  [ACCOUNT_PAGES.PRODUCTS]: {
    href: '/account/products/',
    label: 'Products',
    target: undefined,
    icon: faThList,
  },
  [ACCOUNT_PAGES.PAYMENTS]: {
    href: '/account/payments/',
    label: 'Payments & billing',
    target: undefined,
    icon: faFileInvoiceDollar,
  },
  [ACCOUNT_PAGES.CREDITS]: {
    href: '/account/credits/',
    label: 'Coupons & credits',
    target: undefined,
    icon: faTicket,
  },
  [ACCOUNT_PAGES.PLUS]: {
    href: '/account/plus/',
    label: 'Plus membership',
    target: undefined,
    icon: faPlus,
  },
  [EXTERNAL_LINKS.AFFILIATE]: {
    href: AFFILIATE_URL,
    label: 'Affiliate program',
    target: '_blank',
    icon: faUsers,
  },
  [EXTERNAL_LINKS.LOGOUT]: {
    href: '/accounts/logout/',
    label: 'Log out',
    target: undefined,
    icon: faSignOut,
  },
} as Record<AccountLinkTypes, AccountLinkProps>;

export default memo(function AccountLinks({
  accountPage,
}: Readonly<{
  accountPage?: string;
}>): ReactElement {
  const router = useRouter();
  const dropdownRef = useRef<DropdownProps>();

  const options = Object.keys(ACCOUNT_LINKS).map((key) => {
    const { href, icon, label, target } =
      ACCOUNT_LINKS[key as AccountLinkTypes];
    return {
      label,
      value: href,
      icon,
      target,
      key,
    };
  });

  const defaultValue =
    options.find((option) => option.key === accountPage) ?? options[0];

  // This will force the change of the value when the account page switches from desktop to mobile view
  useEffect(() => {
    defaultValue && dropdownRef?.current?.setValue?.(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className="md:hidden" data-testid="account-link-dropdown">
        <Dropdown
          ref={dropdownRef}
          isNoWrap
          options={options}
          defaultValue={defaultValue}
          onClick={(option) => {
            // @ts-ignore: `key` does exist in `option`
            // but not in `DropdownOptionProps`.
            if (option.key === 'logout') {
              return (window.location.href = option.value.toString());
            }

            router.push(`${option.value}`);
          }}
        />
      </div>
      <div className="hidden md:block">
        {Object.keys(ACCOUNT_LINKS).map((key) => {
          const { href, icon, label, target } =
            ACCOUNT_LINKS[key as AccountLinkTypes];

          return (
            <Link key={key} href={href} target={target} className="relative">
              <div
                key={href}
                className={clsx(
                  'rounded-lg p-4 hover:bg-[#6AD989]/20',
                  (accountPage === key ||
                    (accountPage === ACCOUNT_DEFAULT_KEY &&
                      key === ACCOUNT_PAGES.PROFILE)) &&
                    'bg-[#6AD989]/20',
                )}
              >
                <div className="mr-2 inline-block">
                  <FontAwesomeIcon icon={icon} height="16" />
                </div>
                <span>{label}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
});
