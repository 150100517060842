import Image from 'next/image';
import icon from '~/public/light-bulb-confetti.svg';

export default function SellOnAppsumo() {
  return (
    <a
      className="whitespace-nowrap leading-[24px] hover:underline max-md:mb-6 max-md:flex max-md:gap-x-2 max-md:rounded max-md:bg-green-900 max-md:py-2 max-md:px-4 max-md:text-white"
      href="https://sell.appsumo.com/"
    >
      <Image
        src={icon}
        alt="Sell on AppSumo"
        width="43"
        height="44"
        className="md:hidden"
      />
      <div className="max-md:flex max-md:flex-col">
        <span>
          Sell<span className="md:max-lg:hidden"> on AppSumo</span>
        </span>
        <span className="md:hidden">Earn money selling digital products</span>
      </div>
    </a>
  );
}
