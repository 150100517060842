const currentYear = new Date().getFullYear();
export const BLACK_FRIDAY_CAMPAIGN = 'black_friday';
export const SUMO_DAY_CAMPAIGN = 'sumo_day';
export const SUMO_DAY_SLUG = `sumo-day-${currentYear}`;
export const SUMO_DAY_PARTICIPATION_SLUG = `sumo_days_${currentYear}`;
export const SUMO_DAY_BANNER_TEXT = 'sumo day badge'; // match text from DealSerializer --> banner_details_object()
export const BLACK_FRIDAY_BANNER_TEXT = 'black friday badge'; // match text from DealSerializer --> banner_details_object()
export const COMING_SOON_BANNER_TEXT = 'Coming soon!'; // match text from DealSerializer --> banner_details_object()
export const BLACK_FRIDAY_SLUG = `black-friday-${currentYear}`;
export const BLACK_FRIDAY_PARTICIPATION_SLUG = `black_friday_${currentYear}`;
export const BLACK_FRIDAY_LAST_CALL_SLUG = `black-friday-last-call-${currentYear}`;
export const BLACK_FRIDAY_LAST_CALL_PARTICIPATION_SLUG = `black_friday_last_call_${currentYear}`;
export const LAST_CALL_SLUG = 'last-call';
export const LAST_CALL_PARTICIPATION_SLUG = 'last_call';

export enum CampaignIncentiveVersion {
  CART_SHELF = 'cart-shelf',
  CHECKOUT = 'checkout',
}
