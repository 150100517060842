import clsx from 'clsx';
import { Transition } from '@headlessui/react';
import {
  Toaster as HotToaster,
  ToastIcon,
  toast,
  resolveValue,
} from 'react-hot-toast';
import Image from 'next/image';

import { DefaultToastOptions } from '~/types/toaster';
import closeIcon from '~/public/toaster/close.png';
import closeIconWhite from '~/public/toaster/close-white.png';

// Global definition for our toaster
export function Toaster() {
  const whiteCloseIcon = (
    <Image src={closeIconWhite} alt="Close" width={16} height={16} />
  );
  // Create our own custom toast options to be accessed by our custom toast component
  const toastOptions = {
    duration: 5000,
    success: {
      // overriding with clsx doesn't seem to pick these. so forcing the bg color
      className: '!bg-money-moss text-white',
      closeIcon: whiteCloseIcon,
      iconTheme: {
        primary: '#fff',
        secondary: '#0A840A',
      },
    },
    error: {
      className: '!bg-ready-red text-white',
      closeIcon: whiteCloseIcon,
      iconTheme: {
        primary: '#fff',
        secondary: '#FF5732',
      },
    },
    // Add loading toast options here
    // We'd need to add custom if we want to add custom looking toast options
  } as DefaultToastOptions;

  return (
    <HotToaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      toastOptions={toastOptions}
    >
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className={clsx(
            toastOptions[t.type]?.className,
            'flex flex-row gap-x-2 rounded bg-white p-4 shadow-lg',
          )}
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <ToastIcon toast={t} />
          <p>{resolveValue(t.message, t)}</p>
          {t.type !== 'loading' && (
            <button onClick={() => toast.dismiss(t.id)}>
              <span className="sr-only">Close</span>
              {toastOptions[t.type]?.closeIcon || (
                <Image src={closeIcon} alt="Close" width={16} height={16} />
              )}
            </button>
          )}
        </Transition>
      )}
    </HotToaster>
  );
}
