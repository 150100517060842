import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

interface LayoutProps {
  canScrollBody: boolean;
  hideHeader: boolean;
  isRouteLoading: boolean;
  setCanScrollBody: (val: boolean) => void;
  setHideHeader: (val: boolean) => void;
}

export function useLayout(): LayoutProps {
  const router = useRouter();
  const [canScrollBody, setCanScrollBody] = useState<boolean>(true);
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.toggle(
      'disable-overflow-scroll',
      canScrollBody !== true,
    );
  }, [canScrollBody]);

  useEffect(() => {
    document.body.classList.toggle('hide-fixed-header', hideHeader === true);
  }, [hideHeader]);

  useEffect(() => {
    if (!router) return;

    // when the user navigates to a new page, reset the state of the hidden header so it becomes visible again
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        setLoading(true);
        setHideHeader(false);
      }
    };

    const handleComplete = (url: string) =>
      url === router.asPath && setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return {
    canScrollBody,
    hideHeader,
    isRouteLoading: loading,
    setCanScrollBody,
    setHideHeader: (val: boolean) => !loading && setHideHeader(val),
  };
}
