import Image from 'next/image';
import { useRouter } from 'next/router';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useCallback, useRef, useState, ReactElement, useMemo } from 'react';
import { Button } from '@appsumo/dorado-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useUser from '~/lib/user';
import { getPlusDiscount } from '~/lib/util/helpers';
import { Link } from '~/components/global/Link';
import { AFFILIATE_URL } from '~/constants/global';

import { LOGIN_URL } from '~/lib/util/constants';
import defaultProfile from '~/public/as-profile-default-avatar.svg';
import PlusBadge from '~/public/plus-badge.png';

interface NavItem {
  href: string;
  label: string;
  icon?: ReactElement | null;
}

function LoginSection() {
  return (
    <Button
      href={LOGIN_URL}
      className="whitespace-nowrap px-4 text-center max-md:mt-10 max-md:block max-md:w-full"
    >
      Log in
    </Button>
  );
}

export default function NavUser() {
  const [hoverMenu, setHoverMenu] = useState(false);
  const { user, session } = useUser();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  const items = useMemo(() => {
    const plusNavItem: NavItem = user?.has_plus
      ? {
          href: '/account/plus/',
          label: 'Plus membership',
          icon: null,
        }
      : {
          href: '/plus/',
          label: `Get ${getPlusDiscount(session)}% off`,
          icon: (
            <Image
              data-testid="plus-badge"
              src={PlusBadge}
              alt="Plus badge"
              width={36}
              height={18}
              className="ml-2 inline-block h-[18px] w-[36px]"
              priority
            />
          ),
        };

    return [
      { href: '/account/profile/', label: 'Profile' },
      { href: '/account/products/', label: 'Products' },
      { href: '/account/payments/', label: 'Payments & billing' },
      { href: '/account/credits/', label: 'Coupons & credits' },
      plusNavItem,
      {
        href: AFFILIATE_URL,
        label: 'Affiliate program',
      },
      {
        href: `/accounts/logout/?next=${
          router.pathname === '/account/[[...page]]' ? '' : router.asPath
        }`,
        label: 'Log out',
      },
    ];
  }, [router.pathname, router.asPath, user?.has_plus, session]);

  const onMouseOver = useCallback(() => {
    buttonRef.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Enter',
        bubbles: true,
        cancelable: true,
      }),
    );
    setHoverMenu(true);
  }, [buttonRef]);

  const onMouseLeave = useCallback(() => {
    if (hoverMenu) {
      buttonRef.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
          bubbles: true,
          cancelable: true,
        }),
      );
      setHoverMenu(false);
    }
  }, [buttonRef, hoverMenu]);

  const containerClasses = clsx(
    'md:ml-9 md:min-w-[80px] md:items-center md:justify-end',
    { 'md:mr-6': !user },
  );

  // Preload the user avatar / menu with the user cookie data before we get the user data
  let avatarMenu = user?.is_authenticated ? (
    <div className="flex items-center">
      <Image
        src={defaultProfile}
        alt={'User'}
        width={22}
        height={22}
        className="h-[22px] w-[22px] rounded-full"
        priority
      />
      <FontAwesomeIcon
        icon={faChevronDown}
        width="16"
        height="16"
        className={`ml-2 h-[16px] w-[16px] max-md:hidden`}
      />
    </div>
  ) : (
    <LoginSection />
  );

  // anon or unknown user
  if (!user?.is_authenticated) {
    avatarMenu = <LoginSection />;
  } else if (user?.id) {
    // known user
    avatarMenu = (
      <div>
        <Menu as="div" className="relative z-10" onMouseEnter={onMouseOver}>
          {({ open, close }) => (
            <>
              <div className="flex items-center">
                <Menu.Button ref={buttonRef} className="flex items-center">
                  <Image
                    src={`${user.avatar_small.replace(/22/g, '44')}`}
                    unoptimized
                    alt={user.email}
                    width={22}
                    height={22}
                    className="h-[22px] w-[22px] rounded-full"
                    priority
                  />
                  <span className="ml-4 font-header text-lg font-semibold text-black-pearl md:hidden">
                    {user.username}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width="16"
                    height="16"
                    className={`ml-2 h-[16px] w-[16px] transition duration-300 ease-in-out max-md:hidden ${
                      open ? 'rotate-180' : ''
                    }`}
                  />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                unmount={false}
                enter="md:transition md:ease-out md:duration-100"
                enterFrom="md:transform md:opacity-0 md:scale-95"
                enterTo="md:transform md:opacity-100 md:scale-100"
                leave="md:transition md:ease-in md:duration-75"
                leaveFrom="md:transform md:opacity-100 md:scale-100"
                leaveTo="md:transform md:opacity-0 md:scale-95"
                className="max-md:!block"
              >
                <Menu.Items
                  static
                  className="py-4 font-header outline-none md:absolute md:right-0 md:mt-2 md:w-48 md:rounded-md md:border md:bg-white md:shadow-lg"
                  onMouseLeave={onMouseLeave}
                >
                  {items.map((item) => (
                    <Menu.Item key={item.href}>
                      {({ active }) => (
                        <Link
                          href={item.href}
                          callback={() => close()}
                          className={clsx(
                            'flex w-full py-2 font-semibold text-gray-900 md:px-6',
                            {
                              'bg-gray-100/50': active,
                              'mt-4 max-md:justify-center max-md:rounded-[40px] max-md:border-2 max-md:border-deep-ocean md:mt-0':
                                item.href.indexOf('logout') > 0,
                              'max-md:justify-between':
                                item.href.indexOf('logout') < 0,
                            },
                          )}
                        >
                          <span
                            className={`${
                              item.href.indexOf('logout') > 0
                                ? 'max-md:text-deep-ocean'
                                : ''
                            }`}
                          >
                            {item.label}
                            {item.icon}
                          </span>
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    );
  }

  return <div className={containerClasses}>{avatarMenu}</div>;
}
