// Separated out 'global' because only relates to errors that are not step specific
export const GLOBAL_ERROR_NAME = 'global';
export const COUNTRIES_REQUIRE_POSTAL_CODE = 'US,GB,CA';
export const PAYMENT_METHODS_URL = '/api/cart/stripecards/?format=json';
export enum STEP {
  ACCOUNT = 'account',
  BILLING = 'billing',
  CREDITS_COUPONS = 'credits-coupons',
  PAYMENT = 'payment',
}

export enum ERROR_TYPES {
  PAYMENT = 'payment',
  COUPON = 'coupon',
}

export enum ADDITIONAL_PAYMENT_METHODS {
  CREDITS = 'credits',
  NEW_PAYMENT_METHOD = 'new-payment-method',
  PAYPAL_PAYMENT_METHOD = 'paypal-payment-method',
  // Programatically set when checking out
  // using a coupon to pay the total in full.
  COUPON = 'coupon',
}

export enum STRIPE_HELPER_KEYS {
  stripe = 'stripe',
  cart = 'cart',
  elements = 'elements',
}

export enum CREDIT_COMPONENT_TYPES {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

export const DEFAULT_BILLING_DETAILS = {
  country: { value: '', label: '' },
  postalCode: '',
  taxId: '',
  taxBusinessName: '',
  showTaxCollection: false,
  saveBillingInfo: false,
  geoIPCountry: '',
  is_tax_exempt: false,
  tax_exempt_start_date: '',
  tax_exempt_end_date: '',
};

export const ACCOUNT_CREATION_ERROR_MESSAGE =
  'Account creation in progress, please wait.';

const REFERSH_STR = ' Please refresh the page and try again.';
export const ACCOUNT_ERRORS = {
  GENERIC_SIGNUP: 'Unable to complete sign up.' + REFERSH_STR,
  GENERIC_LOGIN: 'Unable to complete login.' + REFERSH_STR,
  FETCH_USER_SESSION:
    'Sorry we are unable to fetch your account information.' + REFERSH_STR,
  EMAIL: 'Unable to sign up with that email. Please try another.',
  PASSWORD: 'Unable to sign up with that password. Please try another.',
  GOOGLE_LOGIN: 'Sorry, we are unable to log you in with Google.' + REFERSH_STR,
  FACEBOOK_LOGIN:
    'Sorry, we are unable to log you in with Facebook.' + REFERSH_STR,
  FACEBOOK_API: 'Unable to load Facebook API.' + REFERSH_STR,
};

export const CHECKOUT_ERRORS = {
  GENERIC:
    "Whoopsie! Looks like there was a glitch in the server. Don't worry, it's not you--it's us.",
  COUPON: "Oops, that coupon isn't valid.",
  PAYMENT: 'There was an issue with your payment. Please try again.',
  STRIPE_SUBMIT: 'Unable to submit payment. Please try again.',
  MISSING_PAYMENT_METHOD: 'Cannot checkout, missing payment method.',
  STRIPE_REDIRECT:
    'There was a problem processing the Stripe transaction. Please use another payment method.',
  STRIPE_NOT_LOADED:
    'Our payment processor has not loaded. Please refresh the page.',
  RECAPTCHA_NOT_LOADED:
    'Unable to load Recaptcha. Please wait or refresh the page and try again.',
  RECAPTCHA_ERROR:
    "We've encountered an issue with ReCaptcha. Please contact support@appsumo.com if the problem continues.",
  PAYMENT_METHOD:
    'There was a problem processing the transaction. Please use another payment method.',
};

export const AU_COUNTRY_OBJ = { value: 'AU', label: 'Australia' };

export const COUNTRIES_LIST = [
  // List taken from Stripe Element countries dropdown
  { value: 'US', label: 'United States' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua & Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { ...AU_COUNTRY_OBJ },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia & Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'VG', label: 'British Virgin Islands' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'BQ', label: 'Caribbean Netherlands' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo - Brazzaville' },
  { value: 'CD', label: 'Congo - Kinshasa' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czechia' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong SAR China' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao SAR China' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar (Burma)' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PS', label: 'Palestinian Territories' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn Islands' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'São Tomé & Príncipe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia & South Sandwich Islands' },
  { value: 'KR', label: 'South Korea' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'BL', label: 'St. Barthélemy' },
  { value: 'SH', label: 'St. Helena' },
  { value: 'KN', label: 'St. Kitts & Nevis' },
  { value: 'LC', label: 'St. Lucia' },
  { value: 'MF', label: 'St. Martin' },
  { value: 'PM', label: 'St. Pierre & Miquelon' },
  { value: 'VC', label: 'St. Vincent & Grenadines' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard & Jan Mayen' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad & Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks & Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'WF', label: 'Wallis & Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];
export const KLARNA_APPROVED_COUNTRIES = [
  'US',
  'CA',
  'AU',
  'NZ',
  'GB',
  'IE',
  'FR',
  'ES',
  'DE',
  'AT',
  'BE',
  'DK',
  'FI',
  'IT',
  'NL',
  'NO',
  'SE',
];

const countryListToObj = () =>
  COUNTRIES_LIST.reduce((acc, country) => {
    acc[country.value] = country.label;
    return acc;
  }, {} as { [key: string]: string });

export const COUNTRIES_OBJ = countryListToObj();

interface PlusPerk {
  bold?: string;
  description: string;
  cta?: string;
}

export const PLUS_PERKS_ARRAY: Array<PlusPerk> = [
  {
    bold: 'Quarterly coupons:',
    description:
      "We're giving Plus members $25 to spend each quarter if you sign up in 2024",
  },
  {
    bold: '10% off all purchases',
    description: '',
  },
  {
    bold: 'Purchase-protection policy:',
    description:
      '12-month full refund guarantee in the event of a Select product closure',
  },
  {
    bold: 'The Sauce:',
    description: 'Our exclusive business community',
  },
  {
    bold: '',
    description:
      'Extra time to buy or upgrade on top deals you may have missed...',
    cta: 'and much much more!',
  },
];
