import { useMemo, useContext } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { useRouter } from 'next/router';
import { CredentialResponse } from '@react-oauth/google';

import {
  DEFAULT_SWR_OPTIONS,
  START_EMAIL_VERIFICATION_DATE,
} from '~/constants/global';
import { fetchData, fetchJson, StatusError } from '~/lib/fetch';
import {
  FacebookLoginResponseProps,
  UserHookProps,
  UserObjectProps,
  SessionHelperProps,
} from '~/types/user';
import {
  SIGNIN_ENDPOINT_URL,
  USER_SESSION_ENDPOINT_URL,
  SIGNUP_ENDPOINT_URL,
  GOOGLE_REGISTER_ENDPOINT_URL,
  FACEBOOK_REGISTER_ENDPOINT_URL,
  SIGNUP_EMAIL_ENDPOINT_URL,
} from '~/lib/login/constants';
import { GlobalContext } from '~/contexts/global';
import { ACCOUNT_ERRORS } from '~/constants/checkout';
import { PROFILE_DATA_URL } from '~/constants/user';

const DEFAULT_POST_ARGS = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export default function useUser(fallbackData?: UserObjectProps): UserHookProps {
  // HOTFIX - Use the session endpoint to pass any query params
  const router = useRouter();
  const searchParams = useMemo(
    () => router.asPath.split('?')[1],
    [router.asPath],
  );
  const { userState } = useContext(GlobalContext);

  const sessionUrl = searchParams
    ? `${USER_SESSION_ENDPOINT_URL}?${searchParams}`
    : USER_SESSION_ENDPOINT_URL;

  // Some places this hook is called outside SWRConfig scope (ex. useCampaignConfigs)
  // Setting the options manually
  const { mutate, data, error } = useSWR(sessionUrl, fetchJson, {
    ...DEFAULT_SWR_OPTIONS,
    keepPreviousData: true,
    fallbackData: fallbackData ?? {
      user: userState,
    },
  });
  const { mutate: asyncMutate } = useSWRConfig();
  const { user, session, messages, cart } = data || {};

  const sessionHelper = async (): Promise<SessionHelperProps> => {
    const session = await asyncMutate(sessionUrl);
    const error = !session?.user?.id ? ACCOUNT_ERRORS.FETCH_USER_SESSION : null;

    return { session, error };
  };

  // Using a callback here because we need to get the session data after logging in
  // Facebook will give an error if the outer function is async
  const facebookSignIn = (
    response: FacebookLoginResponseProps,
    callback: (asyncSessionData: () => Promise<SessionHelperProps>) => void,
  ): void => {
    const signInError = ACCOUNT_ERRORS.FACEBOOK_LOGIN;

    if (response.authResponse) {
      const { accessToken } = response.authResponse;

      const args = {
        ...DEFAULT_POST_ARGS,
        body: JSON.stringify({ access_token: accessToken }),
      };

      callback(async () => {
        try {
          // Send the access token to the server
          await fetchData(FACEBOOK_REGISTER_ENDPOINT_URL, args);
          return sessionHelper();
        } catch (facebookError) {
          return { session: null, error: signInError };
        }
      });
    } else {
      callback(() => Promise.resolve({ session: null, error: signInError }));
    }
  };

  const googleSignIn = async (
    response: CredentialResponse,
  ): Promise<SessionHelperProps> => {
    const signInError = ACCOUNT_ERRORS.GOOGLE_LOGIN;

    if (response.credential) {
      const accessToken = response.credential;
      const args = {
        ...DEFAULT_POST_ARGS,
        body: JSON.stringify({
          access_token: accessToken,
          id_token: accessToken,
        }),
      };

      try {
        await fetchJson(GOOGLE_REGISTER_ENDPOINT_URL, args);

        return sessionHelper();
      } catch (googleError) {
        // TODO -- find out what this error could be
      }
    }

    return { session: null, error: signInError };
  };

  const signUpEmail = async (
    formData: FormData,
  ): Promise<SessionHelperProps> => {
    try {
      const { error } = await fetchJson(SIGNUP_EMAIL_ENDPOINT_URL, {
        body: formData,
        method: 'POST',
      });

      if (error) {
        return { session: null, error };
      }

      return sessionHelper();
    } catch (e) {
      return {
        session: null,
        error: 'Unable to submit your email, please try again.',
      };
    }
  };

  const signUp = async (
    email: string,
    password: string,
    confirmation: string,
    token: string,
  ): Promise<SessionHelperProps> => {
    const args = {
      ...DEFAULT_POST_ARGS,
      body: JSON.stringify({
        email: email,
        password1: password,
        password2: confirmation,
        token: token,
      }),
    };

    // Create the user (and update the session)
    // Use useSWRConfig mutate (instead of useSWR) so we can return the session and check for errors
    try {
      await fetchData(SIGNUP_ENDPOINT_URL, args);
      return sessionHelper();
    } catch (e: any) {
      let signUpError = ACCOUNT_ERRORS.GENERIC_SIGNUP;

      if (e.response.status === 400) {
        try {
          const { password1, email, non_field_errors } =
            await e.response.json();

          if (email) {
            signUpError = email[0] || ACCOUNT_ERRORS.EMAIL;
          } else if (password1) {
            signUpError = password1[0] || ACCOUNT_ERRORS.PASSWORD;
          } else if (non_field_errors) {
            signUpError = non_field_errors[0] || error;
          }
        } catch (jsonError) {
          // Tried to parse json...
        }
      }

      return { session: null, error: signUpError };
    }
  };

  const signIn = async (
    email: string,
    password: string,
    token: string,
  ): Promise<SessionHelperProps> => {
    const args = {
      ...DEFAULT_POST_ARGS,
      body: JSON.stringify({
        email: email,
        password: password,
        token: token,
      }),
    };

    try {
      // Login (and update the session)
      // Use useSWRConfig mutate (instead of useSWR) so we can return the session and check for errors
      await fetchData(SIGNIN_ENDPOINT_URL, args);
      return sessionHelper();
    } catch (e: any) {
      let signInError = ACCOUNT_ERRORS.GENERIC_LOGIN;

      if (e.response.status === 400) {
        try {
          const { non_field_errors } = await e.response.json();

          if (non_field_errors) {
            signInError = non_field_errors[0] || error;
          }
        } catch (jsonError) {
          // Tried to parse json...
        }
      }

      return { session: null, error: signInError };
    }
  };

  const updateProfile = async (
    data: any,
  ): Promise<[null | Error | StatusError, null | Response]> => {
    const formData = new FormData();
    formData.append('username', user.username);

    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null && key !== 'avatar') {
        // If the value is an object, we want to stringify it
        formData.append(key, JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        // just check if value is not null or undefined
        // we want users to be able to delete fields
        formData.append(key, value as string);
      }
    });

    return fetchData(`${PROFILE_DATA_URL}/${user.id}/`, {
      include: 'credentials',
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (response?.status === 200) {
          return [null, response] as [null, Response];
        }

        // Added for typescript linting. This shouldn't happen since fetchData should throw an error if not 200
        throw new Error(
          `Cannot update profile details at this time. Please try again later. ${
            response?.status ?? 500
          } error.`,
        );
      })
      .catch((error) => {
        return [error, null];
      });
  };

  const joinedAfterEmailVerificationStarted = useMemo(() => {
    return (
      user &&
      new Date(user.date_joined) > new Date(START_EMAIL_VERIFICATION_DATE)
    );
  }, [user]);

  return {
    mutate,
    user,
    messages,
    session,
    cart,
    isLoading: !error && !data,
    isError: error,
    signUp,
    signIn,
    googleSignIn,
    facebookSignIn,
    signUpEmail,
    updateProfile,
    joinedAfterEmailVerificationStarted,
  };
}
