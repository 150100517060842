export const PDP_URL_PREFIX = 'https://appsumo.com/products/';

export const SUBSCRIBE_URL = '/accounts/signup/email-subscribe/';
export const WAITLIST_URL = '/deal/waitlist-signup/';

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i; // NOSONAR

export const APPSUMO_PLUS_DEAL_ID = 1735;
export const APPSUMO_PLUS_DEAL_PLAN_ID = 130;
export const DEFAULT_PLUS_DISCOUNT_PERCENTAGE = 10;

export const CART_SHELF_EXPERIMENT_NAME = 'next-cart-shelf';
export const LOAD_PRIVY_NO_UTM_PARAM = 'privy-no-utm-param';
export const MOBILE_PRIVY_POPUP = 'mobile-privy-popup';
export const EMAIL_ONLY_CHECKOUT_EXPERIMENT_NAME = 'email-only-checkout';
export const SC_ZONE_EXPERIMENT_NAME = 'use-smartcollection-zones'; // variants: ['enabled', 'disabled']
export const CREDITS_COUPONS_EXPERIMENT_NAME = 'checkout-credits-coupons'; // variants: ['enabled', 'disabled']
export const PLUS_MEMBERSHIP_EXPERIMENT_NAME = 'next-plus-membership'; // variants: ['enabled', 'disabled']
export const ACCOUNT_PHONE_NUMBERS_EXPERIMENT_NAME = 'account-phone-numbers'; // variants: ['disabled', 'enabled']
export const PLUS_CANCEL_FRICTION_EXPERIMENT_NAME = 'plus-cancel-friction'; // variants: ['disabled', 'enabled']
export const DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME =
  'discussions-show-all-replies'; // variants: ['disabled', 'enabled']
export const TERMS_FEATURES_EXPERIMENT_NAME = 'terms-features'; // variants: ['disabled', 'enabled']
export const PDP_SKIP_COMMENTS_EXPERIMENT_NAME = 'pdp-skip-comments'; // variants: ['disabled', 'enabled']
export const LTD_QUANTITY_PDP_EXPERIMENT_NAME = 'ltd-quantity-pdp'; // variants: ['enabled', 'disabled']
export const LTD_QUANTITY_SKU_EXPERIMENT_NAME = 'ltd-quantity-sku'; // variants: ['enabled', 'disabled']
export const LEAVE_REVIEW_LINK_EXPERIMENT_NAME = 'leave-review-link'; // variants: ['disabled', 'enabled']
export const CART_TIMER_EXPERIMENT_NAME = 'cart-item-timer'; // variants: ['enabled', 'disabled']
export const LINK_EMAIL_CHECKOUT_EXPERIMENT_NAME = 'link-email-checkout'; // variants: ['disabled', 'enabled']

export const ASKSUMO_COUPON: string = 'SUMOCHAT10';

// Social media share URLs
export const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?';
export const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?';
export const LINKEDIN_SHARE_URL =
  'https://www.linkedin.com/shareArticle?mini=true&';
export const PLAN_TYPES_SUFFIX = ['annual', 'lifetime'];

// Adds optional group to check server side middleware routing
export const PDP_REGEX =
  /\/products\/[a-zA-Z0-9_-]+\/(!!.*__user_state=.*\/)?$/;
export const IS_INDEXABLE_PAGE_REGEX =
  /\/products\/[a-zA-Z0-9_-]+\/(reviews|questions)\/[a-zA-Z0-9_-]+\/$/;
export const IS_AGGREGATE_PAGE_REGEX =
  /\/products\/[a-zA-Z0-9_-]+\/(reviews|questions)\/$/;
// strip any query or hash params prior to testing (e.g. had an issue with ?next=/products/...)
export const isPDP = (url: string) =>
  PDP_REGEX.test(url.split('?')?.[0]?.split('#')?.[0] ?? '');
export const isIndexablePage = (url: string) =>
  IS_INDEXABLE_PAGE_REGEX.test(url);
export const isAggregatePage = (url: string) =>
  IS_AGGREGATE_PAGE_REGEX.test(url);
export enum DEAL_STAGE {
  INACTIVE = 'inactive',
  EARLY_ACCESS = 'early access',
  GENERAL_ACCESS = 'general access',
  LAST_CALL = 'last call',
}
export enum GA4FormContentValue {
  spotlightHome10off = 'spotlight-home-10-off',
  spotlight404Page10Off = 'spotlight-404-page-10-off',
  signUpPdpPricingCardSoldOut = 'sign-up-pdp-pricing-card-sold-out',
  signUpInfoPdpPricingCardInfo = 'sign-up-info-pdp-pricing-card-info',
  alternativeTo10Off = 'alternative-to-10-off',
}
export const CANONICAL_WITH_PAGE_QUERY = [
  '/products/[slug]/questions',
  '/products/[slug]/reviews',
];
export const BIG_BOI_DEFAULT_RGB_BACKGROUND = 'rgb(27, 27, 27)';
export const LOGIN_URL = '/accounts/login/';

// middleware.ts rewrites the URL from '/' to '/homepage/' from the server.
export const HOMEPAGE_PATHNAMES = ['/', '/homepage/', '/homepage/[sc_groups]'];
